import { MusicNote, PeopleAltOutlined } from "@mui/icons-material"
import { Box, Chip, Link } from "@mui/material"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { IconWithText } from ".."
import useMUIMediaQuery from "../../hooks/useMUIMedia"
import { ProjectProps } from "../../types"
import { createDownloadUrl } from "../../util"

export interface ProjectItemProps {
  /**
   * A WMC project
   */
  project: ProjectProps
}

/**
 * Project Item
 */
export default function ProjectItem({ project }: ProjectItemProps) {
  const navigate = useNavigate()
  const { isMobileUp } = useMUIMediaQuery()
  const { image, styles, instruments, status } = project
  const projectImageUrl =
    "https://app.womucon.com/static/media/logo-sq.fbd206f27da195e9dc61.png" ??
    createDownloadUrl(image?.downloadUrl)
  const instrumentText = useMemo(() => {
    return instruments.length > 1 ? `${instruments[0]} + ${instruments.length - 1}` : instruments[0]
  }, [instruments])

  const stylesText = useMemo(() => {
    return styles.length > 1 ? `${styles[0]} + ${styles.length - 1}` : styles[0]
  }, [styles])

  return (
    <Box
      sx={{
        position: "relative",
        m: "5px",
        width: {
          xs: "100%",
          md: "calc(50% - 20px)",
          lg: "calc(33% - 10px)",
          xl: "calc(20% - 10px)",
        },
        display: {
          xs: "flex",
          md: "block",
        },
      }}
    >
      <Link onClick={() => navigate(`/projects/${project._id}`)}>
        <CardMedia
          sx={{
            height: {
              xs: "100%",
              md: 300,
            },
            width: {
              xs: window.innerWidth / 3,
              md: "100%",
            },
          }}
          image={projectImageUrl}
          title="project cover img"
        />
      </Link>
      <Box
        sx={{
          display: { xs: "flex", md: "block" },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <CardContent>
          <Typography variant="h5" component="div">
            <Link
              sx={{ textDecoration: "none", color: "white" }}
              onClick={() => navigate(`/projects/${project._id}`)}
            >
              {project.name}
            </Link>
          </Typography>
        </CardContent>
        <CardActions>
          {[
            {
              iconComponent: <MusicNote />,
              text: `${project.users.length} ${isMobileUp ? "Musicians" : ""}`,
            },
            {
              iconComponent: <PeopleAltOutlined />,
              text: `${project.followers.length} ${isMobileUp ? "Followers" : ""}`,
            },
          ].map(({ iconComponent, text }) => {
            return <IconWithText key={text} iconComponent={iconComponent} text={text} />
          })}
        </CardActions>
        <CardActions sx={{ flexWrap: "wrap" }}>
          <Chip key={instrumentText} label={instrumentText} variant="outlined" sx={{ mt: 1 }} />

          <Chip key={stylesText} label={stylesText} variant="outlined" sx={{ mt: 1 }} />
        </CardActions>
      </Box>
    </Box>
  )
}
