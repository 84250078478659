import { Box, CardMedia, IconButton, Link } from "@mui/material"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { colors } from "../../theme/colors"
import { createDownloadUrl } from "../../util"

import WMC_LOGO from "../../assets/brands/logo-sq.png"
import ShortcutIcon from "@mui/icons-material/Shortcut"
import TurnedInIcon from "@mui/icons-material/TurnedIn"
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot"
import BuyNowOrAddToCartButton from "component/BuyNowOrAddToCartButton/BuyNowOrAddToCartButton"
import DownloadButton from "component/DownloadButton/DownloadButton"
import { useNavigate } from "react-router-dom"
import { ProjectAPI } from "api"
import React from "react"
type MusicDetailMusicCardProps = {
  _id: string
  name: string
  description: string
  imageUrl: string
  image: {
    downloadUrl: string
  }
  saved: boolean
  purchased: boolean
  price: number
  project: string
}
/**
 * Music Detail Music Card Section
 */
export default function MusicDetailMusicCard(props: MusicDetailMusicCardProps) {
  const navigate = useNavigate()
  const { _id, name, description, saved, image, purchased, price, project } = props
  const [projectDetail, setProjectDetail] = React.useState({
    _id: undefined,
    users: [] as { role: string; user: { name: string } }[],
    comments: [],
    musicSheet: {},
    instrumentSheet: [],
    files: [],
    fileToken: undefined,
    instruments: [],
    metreUpper: 0,
    metreLower: 0,
    tempo: "",
    styles: [],
    musicKey: "",
    description: "",
    image: undefined,
  })
  React.useEffect(() => {
    const trigger = async () => {
      if (project) {
        const getProjectRes = await ProjectAPI.getProject(project)
        if (getProjectRes.err) {
          console.log(getProjectRes.err)
          return
        }

        setProjectDetail(getProjectRes.res)
      }
    }

    trigger()
  }, [project])
  const projectImageUrl =
    createDownloadUrl(image?.downloadUrl) ??
    "https://app.womucon.com/static/media/logo-sq.fbd206f27da195e9dc61.png"

  return (
    <Box
      sx={{
        position: "relative",
        m: "5px",
        maxWidth: 345,
        display: "block",
      }}
    >
      <Link onClick={() => navigate(`/musicDetail/${_id}`)}>
        <CardMedia
          sx={{
            height: 300,
            width: "100%",
          }}
          image={projectImageUrl || WMC_LOGO}
          title="project cover img"
        />
      </Link>
      <Box
        sx={{
          display: "block",
          flexDirection: "row",
        }}
        mt={1}
      >
        <Grid justifyContent={"space-between"} display={"flex"} mb={1}>
          <Box flex={3}>
            <Typography variant="h6" component="div">
              <Link
                sx={{ textDecoration: "none", color: "white" }}
                onClick={() => navigate(`/musicDetail/${_id}`)}
              >
                {name}
              </Link>
            </Typography>

            <Typography variant="caption" component="div" color={colors.grey2}>
              {description}
            </Typography>
            <Typography variant="body1" color={colors.grey2}>
              {projectDetail.users.find((t) => t.role === "LEAD")?.user.name ?? ""}
            </Typography>
          </Box>
          {!purchased && (
            <Box p={1} bgcolor={colors.grey3} flex={1} height={"50%"} mt={1}>
              <Typography variant="body2">HKD {price}</Typography>
            </Box>
          )}
        </Grid>

        <Grid display={"flex"} justifyContent="space-between" alignItems={"center"}>
          {purchased ? <DownloadButton /> : <BuyNowOrAddToCartButton />}

          <Box display={"flex"} alignItems={"flex-end"}>
            <IconButton
              edge="start"
              sx={{
                padding: "4px, 10px, 4px, 10px",
              }}
              onClick={() => {}}
            >
              <ShortcutIcon />
            </IconButton>
            <IconButton
              edge="start"
              sx={{
                padding: "4px, 10px, 4px, 10px",
              }}
              onClick={() => {}}
            >
              {saved ? <TurnedInIcon /> : <TurnedInNotIcon />}
            </IconButton>
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}
