import { MusicAPI } from "../../api"
import { useNotification } from "../../context/NotificationProvider"
import { useEffect, useState } from "react"
import { Page, PageContainer } from "../../component"
import { Grid, LinearProgress, Typography } from "@mui/material"
import MusicList from "./MusicList"

const audio = new Audio()

/**
 * This is the browse music page, all music from finished PUBLIC projects will be returned here for any user to listen to
 */
export default function BrowseMusicPage() {
  const { openNotification } = useNotification()
  const [allMusic, setAllMusic] = useState([])
  const [loadingMusic, setLoadingMusic] = useState(false)

  audio.addEventListener("canplay", () => {
    audio.play()
    setLoadingMusic(false)
  })

  const handlePlayMusic = (url: string) => {
    audio.src = url
    audio.load()
    setLoadingMusic(true)
  }

  const handleStopMusic = () => {
    audio.pause()
  }

  useEffect(() => {
    const trigger = async () => {
      const { res, error } = await MusicAPI.getMusic()
      if (error) {
        openNotification("ERROR", error.message)

        return
      }
      setAllMusic(res)
    }
    trigger()

    return function cleanUp() {
      audio.pause()
    }
  }, [])

  if (allMusic?.length === 0) {
    return (
      <Page pageId={BrowseMusicPage} noPadding>
        <PageContainer>
          <Typography variant="h4">Music Loading...</Typography>
        </PageContainer>
      </Page>
    )
  }

  return (
    <Page pageId={BrowseMusicPage} noPadding>
      {loadingMusic ? <LinearProgress color="success" /> : <div style={{ height: "4px" }}></div>}

      <PageContainer>
        <Grid container spacing={1}>
          <MusicList
            allMusic={allMusic}
            handlePlayMusic={handlePlayMusic}
            handleStopMusic={handleStopMusic}
          />
        </Grid>
      </PageContainer>
    </Page>
  )
}
