import { Box, Button, Divider, SxProps, Theme } from "@mui/material"

import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart"

const BUTTON_WIDTH = 150
const BUTTON_BORDER_RADIUS = 16

type BuyNowOrAddToCartButtonProps = {
  sx?: SxProps<Theme>
  onBuyNowClick?: () => void
  onAddToCartClick?: () => void
}
/**
 * Market Place Buy Now Or Add To Cart Button
 */
export default function BuyNowOrAddToCartButton(props: BuyNowOrAddToCartButtonProps) {
  const { sx, onBuyNowClick, onAddToCartClick } = props

  return (
    <Box
      sx={{
        ...sx,
        width: BUTTON_WIDTH,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: BUTTON_BORDER_RADIUS,
        overflow: "hidden",
      }}
    >
      <Button sx={{ textTransform: "none" }} onClick={onBuyNowClick}>
        Buy Now
      </Button>
      <Divider orientation="vertical" flexItem />
      <Button onClick={onAddToCartClick}>
        <AddShoppingCartIcon />
      </Button>
    </Box>
  )
}
