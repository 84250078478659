import React from "react"
import { Typography, Paper } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { AgreementCheckBox } from "../../component"

interface UploadSongPreviewProps {
  children: React.ReactNode
  onAgreementChange: () => void
  agreement: boolean
}

/**
 * Component to return the project preview title and all content as a children
 */
export default function UploadSongPreview({
  children,
  agreement,
  onAgreementChange,
}: UploadSongPreviewProps) {
  const theme = useTheme()
  return (
    <>
      <Typography variant="formTitle">Preview and Publish</Typography>
      <Paper
        square
        sx={{
          px: { xs: theme.spacing(3), lg: theme.spacing(6) },
          py: theme.spacing(3),
          my: theme.spacing(2),
          bgcolor: "#151515",
        }}
      >
        {children}
      </Paper>

      <AgreementCheckBox error={false} onChange={onAgreementChange} checked={agreement} />
    </>
  )
}
