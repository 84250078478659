/* eslint-disable no-unreachable */
import * as React from "react"
import { Grid, Tabs, Tab, useTheme, useMediaQuery, LinearProgress, Container } from "@mui/material"
import PersonIcon from "@mui/icons-material/Person"
import RuleIcon from "@mui/icons-material/Rule"
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer"
import { useParams } from "react-router-dom"
import { useNotification } from "../../context/NotificationProvider"
import { ProjectAPI, TaskAPI, UserAPI } from "../../api"
import { Page, RFragment, TabPanel } from "../../component"
import { colors } from "../../theme/colors"
import Info from "./Info"
import Attachment from "./Attachment"
import Musician from "./tabs/Musician"
import Comment from "./tabs/Comment"
import Task from "./tabs/Task"
import UploadSection from "./UploadSection"
import RecordingSection from "./RecordingSection"

const tabsContentStyle = {
  backgroundColor: colors.darkGrey2,
  "@media (min-width:0px) and (max-width:599.95px)": {
    backgroundColor: "unset",
    height: "unset",
  },
  height: "calc(100vh - 64px)",
}

const tabsGroupStyle = {
  backgroundColor: colors.darkGrey2,
  "@media (min-width:0px) and (max-width:599.95px)": {
    backgroundColor: "unset",
  },
}

const accessLv = {
  general: ["GUEST"],
  admin: ["ADMIN", "LEAD"],
  user: ["ADMIN", "LEAD", "MUSICIAN"],
  invited: ["INVITED"],
}

const projectDetailInitProps = {
  _id: undefined,
  users: [],
  comments: [],
  musicSheet: {},
  instrumentSheet: [],
  files: [],
  fileToken: undefined,
  instruments: [],
}

/**
 * Project Detail page
 */
export default function ProjectDetailPage() {
  const { openNotification } = useNotification()
  const { projectId } = useParams()
  const [pageState, setPageState] = React.useState({
    projectDetail: projectDetailInitProps,
    recommendedUsers: [],
    projectDetailUpdate: 1,
    tasks: [],
    tasksUpdate: 1,
    error: undefined,
  })

  React.useEffect(() => {
    const trigger = async () => {
      const getProjectRes = await ProjectAPI.getProject(projectId)
      if (getProjectRes.err) {
        updatePageState({ error: getProjectRes.err })
        return
      }

      updatePageState({
        projectDetail: getProjectRes.res,
      })
    }

    trigger()
  }, [projectId, pageState.projectDetailUpdate])

  React.useEffect(() => {
    const trigger = async () => {
      const getTasksRes = await TaskAPI.getTasks(projectId)
      if (getTasksRes.err) {
        updatePageState({ error: getTasksRes.err })
        return
      }

      updatePageState({
        tasks: getTasksRes.res,
      })
    }

    trigger()
  }, [projectId, pageState.tasksUpdate])

  React.useEffect(() => {
    const trigger = async () => {
      const usersRes = await UserAPI.searchUserByInstruments(pageState.projectDetail.instruments)

      if (usersRes.err) return

      const currentUsersIds = pageState.projectDetail.users.map(
        ({ user }: { user: { _id: string } }) => user._id
      )
      const filteredUsers = usersRes.res.filter(
        ({ _id }: { _id: string }) => !currentUsersIds.includes(_id)
      )

      updatePageState({
        recommendedUsers: filteredUsers,
      })
    }

    if (pageState.projectDetail?.instruments) {
      trigger()
    }
  }, [projectId, pageState.projectDetail.instruments, pageState.projectDetail.users])

  const updatePageState = (newState: any) => {
    setPageState((prevState) => {
      return {
        ...prevState,
        ...newState,
      }
    })
  }

  const triggerPageDetailUpdate = ({ error }: { error: Error }) => {
    if (error) {
      openNotification("ERROR", error.message)
      return
    }
    updatePageState({ projectDetailUpdate: pageState.projectDetailUpdate + 1 })
    openNotification("SUCCESS", "Success")
  }

  const triggerTasksUpdate = ({ error }: { error: Error }) => {
    if (error) {
      openNotification("ERROR", error.message)
      return
    }
    updatePageState({ tasksUpdate: pageState.tasksUpdate + 1 })
    openNotification("SUCCESS", "Success")
  }

  const handleUpdateInfo = async (rawData: any) => {
    triggerPageDetailUpdate({
      ...(await ProjectAPI.updateInfo(projectId, rawData)),
    })
  }

  const handleCreateTask = async (rawData: any) => {
    triggerTasksUpdate({
      ...(await TaskAPI.createTask(projectId, rawData)),
    })
  }

  const handleEditTask = async (taskId: string, rawData: any) => {
    triggerTasksUpdate({
      ...(await TaskAPI.editTask(taskId, rawData)),
    })
  }

  const handleDeleteTask = async (taskId: string) => {
    triggerTasksUpdate({
      ...(await TaskAPI.deleteTask(taskId)),
    })
  }

  const handleCreateComment = async (rawData: any) => {
    triggerPageDetailUpdate({
      ...(await ProjectAPI.createComment(projectId, rawData)),
    })
  }

  const handleDeleteMusic = async (fileId: string) => {
    triggerPageDetailUpdate({
      ...(await ProjectAPI.deleteMusicFile(projectId, fileId)),
    })
  }

  const handleUploadInstrumentSheet = async (
    selectedInstrument: string,
    file: File | undefined
  ) => {
    triggerPageDetailUpdate({
      ...(await ProjectAPI.uploadInstrumentSheet(projectId, selectedInstrument, file)),
    })
  }

  const handleUploadInstrumentMusic = async (
    selectedInstrument: string,
    file: File | undefined
  ) => {
    triggerPageDetailUpdate({
      ...(await ProjectAPI.uploadInstrumentMusic(projectId, selectedInstrument, file)),
    })
  }

  const handleFinishProject = async (file: File) => {
    triggerPageDetailUpdate({
      ...(await ProjectAPI.finishProject(projectId, file)),
    })
  }

  const handleFollowProject = async () => {
    triggerPageDetailUpdate({
      ...(await ProjectAPI.followProject(projectId)),
    })
  }

  const handleUnFollowProject = async () => {
    triggerPageDetailUpdate({
      ...(await ProjectAPI.unfollowProject(projectId)),
    })
  }

  const handleRequestToJoinProject = async (data: { instrument: string }) => {
    triggerPageDetailUpdate({
      ...(await ProjectAPI.requestToJoin(projectId, data)),
    })
    // Purposely reload the page to get the new user info
    location.reload()
  }

  const handleApproveUserToProject = async (
    projectId: string | undefined,
    userId: string,
    instrument: string
  ) => {
    triggerPageDetailUpdate({
      ...(await ProjectAPI.approveUserToProject(projectId, userId, instrument)),
    })
  }

  const handleDenyUserToProject = async (
    projectId: string | undefined,
    userId: string,
    instrument: string
  ) => {
    triggerPageDetailUpdate({
      ...(await ProjectAPI.denyUserToProject(projectId, userId, instrument)),
    })
  }

  const handleInviteUserToProject = async (data: { userId: string; instrument: string }) => {
    triggerPageDetailUpdate({
      ...(await ProjectAPI.inviteUser(projectId, data)),
    })
    // Purposely reload the page to get the new user info
    location.reload()
  }

  const handleAcceptInviteToProject = async (data: { instrument: string }) => {
    triggerPageDetailUpdate({
      ...(await UserAPI.acceptInvite(data)),
    })
    // Purposely reload the page to get the new user info
    location.reload()
  }

  const handleDenyInviteToProject = async (data: { instrument: string }) => {
    triggerPageDetailUpdate({
      ...(await UserAPI.denyInvite(data)),
    })
    // Purposely reload the page to get the new user info
    location.reload()
  }

  const { projectDetail, error, tasks } = pageState
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))
  const tabsOrientation = isDesktop ? "vertical" : "horizontal"
  const tabsIconPos = isDesktop ? "top" : "start"
  const [selectTab, setSelectTab] = React.useState<number>(0)

  if (!projectDetail?._id) return <LinearProgress color="success" />
  if (error) return <React.Fragment>{error}</React.Fragment>

  return (
    <Page pageId={ProjectDetailPage} noPadding>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item container xs={12} md={7} paddingTop={5} sx={{ pr: { xs: 0, md: 3 } }}>
            <Info
              {...projectDetail}
              updateHandler={handleUpdateInfo}
              handleFinishProject={handleFinishProject}
              handleFollowProject={handleFollowProject}
              handleUnFollowProject={handleUnFollowProject}
              handleRequestToJoinProject={handleRequestToJoinProject}
              handleAcceptInviteToProject={handleAcceptInviteToProject}
              handleDenyInviteToProject={handleDenyInviteToProject}
            />

            <RFragment allow={accessLv.user}>
              <Attachment {...projectDetail} handleDeleteMusic={handleDeleteMusic} />
              <UploadSection
                {...projectDetail}
                handleUploadInstrumentSheet={handleUploadInstrumentSheet}
                handleUploadInstrumentMusic={handleUploadInstrumentMusic}
              />
              <RecordingSection
                {...projectDetail}
                handleUploadInstrumentMusic={handleUploadInstrumentMusic}
              />
            </RFragment>
          </Grid>

          <RFragment allow={accessLv.user}>
            <Grid item container xs={12} md={5} sx={{ pl: { xs: 0, md: 3 } }}>
              <Grid item xs={12} md={2}>
                <Tabs
                  orientation={tabsOrientation}
                  variant="fullWidth"
                  value={selectTab}
                  onChange={(e, val) => setSelectTab(val)}
                  aria-label="Vertical tabs example"
                  sx={tabsGroupStyle}
                >
                  <Tab
                    icon={<PersonIcon />}
                    label={isDesktop ? "Musician" : undefined}
                    iconPosition={tabsIconPos}
                    sx={{ fontSize: 10 }}
                  />
                  <Tab
                    icon={<RuleIcon />}
                    label={isDesktop ? "Task List" : undefined}
                    iconPosition={tabsIconPos}
                    sx={{ fontSize: 10 }}
                  />
                  <Tab
                    icon={<QuestionAnswerIcon />}
                    label={isDesktop ? "Comment" : undefined}
                    iconPosition={tabsIconPos}
                    sx={{ fontSize: 10 }}
                  />
                </Tabs>
              </Grid>
              <Grid item container xs={12} md={10}>
                <Grid item sx={tabsContentStyle} xs={12}>
                  <TabPanel value={selectTab} index={0}>
                    <Musician
                      {...projectDetail}
                      recommendedUsers={pageState.recommendedUsers}
                      handleApproveUserToProject={handleApproveUserToProject}
                      handleDenyUserToProject={handleDenyUserToProject}
                      handleInviteUserToProject={handleInviteUserToProject}
                    />
                  </TabPanel>
                  <TabPanel value={selectTab} index={1}>
                    <Task
                      projectId={projectDetail._id}
                      tasks={tasks}
                      users={projectDetail.users}
                      handleCreateTask={handleCreateTask}
                      handleEditTask={handleEditTask}
                      handleDeleteTask={handleDeleteTask}
                    />
                  </TabPanel>
                  <TabPanel value={selectTab} index={2}>
                    <Comment {...projectDetail} handleCreateComment={handleCreateComment} />
                  </TabPanel>
                </Grid>
              </Grid>
            </Grid>
          </RFragment>
        </Grid>
      </Container>
    </Page>
  )
}
