import React, { useState, useEffect, useMemo } from "react"
import { Box, Button, Stack, Typography } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { useNavigate, useParams } from "react-router-dom"
import { colors } from "../../theme/colors"
import { SubscriptionAPI, SubscriptionPlanAPI } from "../../api"

interface Plan {
  planType: string
  title: string
  price: number
  billingCycle: "monthly" | "yearly"
  features: string[]
  isContactUs?: boolean
  stripeId?: string
}

/**
 *
 * Subscription Acknowledgement Page
 */
export default function SubscriptionAcknowledgementPage() {
  const { subscriptionId } = useParams()
  const [subscription, setSubscription] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const navigate = useNavigate()
  const [plans, setPlans] = useState<Plan[]>([]) // Store plans retrieved from API
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await SubscriptionPlanAPI.getAll()
        if (response?.err) {
          setError(response.err)
          return
        }
        setPlans(response?.res?.plans || [])
      } catch (err) {
        console.error("Error fetching plans:", err)
        setError("Failed to load subscription plans.")
      }
    }

    fetchPlans()
  }, [])
  const planDetail = useMemo(() => {
    return plans.find((t) => t.planType === subscription?.planType)
  }, [plans, subscription?.planType])

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const { res } = await SubscriptionAPI.getDetail(subscriptionId)
        console.log(res)
        setSubscription(res)
      } catch (err) {
        console.error("Error fetching subscription:", err)
        setError("Failed to load subscription details.")
      } finally {
        setLoading(false)
      }
    }

    if (subscriptionId) {
      fetchSubscription()
    }
  }, [subscriptionId])

  if (loading) {
    return (
      <Box
        sx={{
          color: "#fff",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 4,
        }}
      >
        <Typography variant="h5">Loading subscription details...</Typography>
      </Box>
    )
  }

  if (error) {
    return (
      <Box
        sx={{
          color: "#fff",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 4,
        }}
      >
        <Typography variant="h5" color="error">
          {error}
        </Typography>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        color: "#fff",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 4,
      }}
    >
      <Stack alignItems="center" spacing={2}>
        <CheckCircleIcon sx={{ fontSize: 60, color: "#00e676" }} />
        <Typography variant="h4" align="center">
          Thank you!
        </Typography>
        <Typography variant="body1" align="center">
          {`${planDetail?.title} plan has been subscribed successfully`}
        </Typography>
      </Stack>

      <Stack
        sx={{
          padding: 2,
          marginTop: 4,
          width: "100%",
          maxWidth: 600,
          border: `1px solid ${colors.darkGrey2}`,
        }}
      >
        <Box sx={{ padding: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            {planDetail?.title} plan
          </Typography>
          <Box sx={{ display: "flex", alignItems: "baseline" }}>
            <Typography variant="h4" sx={{ color: "#fff", marginRight: 1 }}>
              {planDetail?.price}
            </Typography>
            <Typography variant="body2" sx={{ color: "#aaa" }}>
              Billing {planDetail?.billingCycle}
            </Typography>
          </Box>
          <Box
            component="ul"
            sx={{
              paddingY: 2,
              paddingX: 4,
              marginTop: 2,
              backgroundColor: colors.darkGrey2,
            }}
          >
            {planDetail?.features?.map((feature, index) => (
              <Typography
                component="li"
                variant="body2"
                key={index}
                sx={{ color: "#fff", marginBottom: 1 }}
              >
                {feature}
              </Typography>
            ))}
          </Box>
        </Box>
      </Stack>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#ff7d00",
          color: "#fff",
          textTransform: "none",
          marginTop: 4,
          paddingX: 4,
        }}
        onClick={() => navigate("/history/subscription")}
      >
        {"View Subscription Record"}
      </Button>
    </Box>
  )
}
