import { config } from "../config/client-config"
import { getRequest, postRequest } from "./request"

const SavedMusic: any = {}

SavedMusic.saveMusic = async (musicId: string) => {
  try {
    const data = {
      musicId,
    }
    console.log(data)
    await postRequest({
      url: `/savedMusic/save`,
      data,
    })
  } catch (error) {
    console.error("Error recording share:", error)
  }
}

SavedMusic.unsaveMusic = async (musicId: string) => {
  try {
    const data = {
      musicId,
    }
    await postRequest({
      url: `/savedMusic/unsave`,
      data,
    })
  } catch (error) {
    console.error("Error recording share:", error)
  }
}

SavedMusic.getSavedMusic = async () => {
  return await getRequest({ url: `/savedMusic/saved` })
}

export default SavedMusic
