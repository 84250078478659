import React, { useEffect } from "react"
import { UserAPI } from "../../api"
import { useParams } from "react-router-dom"
import ProfileHeader from "../../component/ProfileHeader/ProfileHeader"
import ProfileProjects from "../../component/ProfileProjects/ProfileProjects"
import { createDownloadUrl } from "../../util"

/**
 * This is the user portfolio Page, this page should be viewable to anyone
 * The editing of portfolio page should be restricted to the user itself and possibly the admin
 */
export default function PortfolioPage() {
  const { userId } = useParams()
  const [userInfo, setUserInfo] = React.useState<any>()

  useEffect(() => {
    const trigger = async () => {
      const { res, error } = await UserAPI.searchUserById(userId)
      setUserInfo(res)
    }

    trigger()
  }, [userId])

  if (!userInfo) return <></>

  const coverImageUrl = createDownloadUrl(userInfo.coverImage?.downloadUrl)

  return (
    <>
      <ProfileHeader {...userInfo} coverImageUrl={coverImageUrl} />

      <ProfileProjects {...userInfo} />
    </>
  )
}
