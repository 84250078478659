import moment from "moment"
//@ts-ignore
import { musicKeyList } from "@womucon/share"
import { ProjectFormStateProps } from "../../types/projectTypes"

export const musicKeys = musicKeyList

export const tempoMarks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 260,
    label: "260",
  },
]

export const ProjectFormInitiateState: ProjectFormStateProps = {
  type: "NORMAL",
  name: "",
  description: "",
  instruments: [],
  styles: [],
  demoFile: undefined,
  demoSheet: undefined,
  deadline: moment().add(30, "days").calendar(),
  tempo: 80,
  musicKey: "Cb Major",
  metreUpper: 1,
  metreLower: 1,
  imageUrl: undefined,
  musicType: "",
  currency: "",
  price: "",
}

export const PROJECT_TYPES = ["NORMAL", "SAMPLE"]

export const MUSIC_TYPES = ["PREMIUM", "STANDARD"]
