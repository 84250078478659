import { Box, Button, Divider, IconButton, SxProps, Theme } from "@mui/material"
import DownloadIcon from "@mui/icons-material/Download"
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart"
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot"
import TurnedInIcon from "@mui/icons-material/TurnedIn"
import ShortcutIcon from "@mui/icons-material/Shortcut"

const BUTTON_WIDTH = 140
const BUTTON_BORDER_RADIUS = 16

/**
 * Download button in market place table
 */
function Download({ sx, onClick }: { sx?: SxProps<Theme>; onClick?: () => void }) {
  return (
    <Button
      sx={{
        ...sx,
        width: BUTTON_WIDTH,
        display: "inline-flex",
        alignItems: "center",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: BUTTON_BORDER_RADIUS,
        overflow: "hidden",
        textTransform: "none",
      }}
      startIcon={<DownloadIcon />}
      onClick={onClick}
    >
      Download
    </Button>
  )
}

/**
 * Buy now button in market place table
 */
// eslint-disable-next-line react/no-multi-comp
function BuyNowOrAddToCart({
  sx,
  onBuyNowClick,
  onAddToCartClick,
}: {
  sx?: SxProps<Theme>
  onBuyNowClick?: () => void
  onAddToCartClick?: () => void
}) {
  return (
    <Box
      sx={{
        ...sx,
        width: BUTTON_WIDTH,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: BUTTON_BORDER_RADIUS,
        overflow: "hidden",
      }}
    >
      <Button sx={{ textTransform: "none" }} onClick={onBuyNowClick}>
        Buy Now
      </Button>
      <Divider orientation="vertical" flexItem />
      <Button onClick={onAddToCartClick}>
        <AddShoppingCartIcon />
      </Button>
    </Box>
  )
}

/**
 * Share/ forward button in market place table
 */
// eslint-disable-next-line react/no-multi-comp
function Share({ sx, onClick }: { sx?: SxProps<Theme>; onClick?: () => void }) {
  return (
    <IconButton sx={sx} onClick={onClick}>
      <ShortcutIcon />
    </IconButton>
  )
}

/**
 * Bookmark button in market place table
 */
// eslint-disable-next-line react/no-multi-comp
function Bookmark({
  sx,
  onClick,
  saved,
}: {
  sx?: SxProps<Theme>
  onClick?: () => void
  saved: boolean
}) {
  return (
    <IconButton sx={sx} onClick={onClick}>
      {saved ? <TurnedInIcon /> : <TurnedInNotIcon />}
    </IconButton>
  )
}

export const Buttons = {
  Download,
  BuyNowOrAddToCart,
  Share,
  Bookmark,
}
