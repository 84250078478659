import { config } from "../config/client-config"
import { postRequest } from "./request"

const Share: any = {}

// Function to record the share event in the backend
const recordShare = async ({ musicId, destination }: { musicId: string; destination: string }) => {
  try {
    const data = {
      musicId,
      destination,
    }
    await postRequest({
      url: `/shareRecord/share`,
      data,
    })
  } catch (error) {
    console.error("Error recording share:", error)
  }
}

// Function to share via WhatsApp
Share.shareToWhatsApp = async (musicId: string, name: string) => {
  const text = `Check out ${name} on Womucon!`
  const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(text)}%20${encodeURIComponent(
    `https://app.womucon.com/musicDetail/${musicId}`
  )}`
  window.open(whatsappUrl, "_blank")
  await recordShare({ musicId, destination: "WhatsApp" })
}

// Function to share via Facebook
Share.shareToFacebook = async (musicId: string) => {
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `https://app.womucon.com/musicDetail/${musicId}`
  )}`
  window.open(facebookUrl, "_blank")
  await recordShare({ musicId, destination: "Facebook" })
}

// Function to share via Twitter
Share.shareToTwitter = async (musicId: string, name: string) => {
  const text = `Check out ${name} on Womucon!`
  const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    text
  )}&url=${encodeURIComponent(`https://app.womucon.com/musicDetail/${musicId}`)}`
  window.open(twitterUrl, "_blank")
  await recordShare({ musicId, destination: "Twitter" })
}

// Function to copy the link
Share.copyLink = async (musicId: string) => {
  try {
    await navigator.clipboard.writeText(`${config.appUrl}/musicDetail/${musicId}`)
    alert("Link copied to clipboard")
    await recordShare({ musicId, destination: "Copy Link" })
  } catch (error) {
    console.error("Error copying link:", error)
  }
}

// Function to share via LinkedIn
Share.shareToLinkedIn = async (musicId: string, name: string) => {
  const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
    `https://app.womucon.com/musicDetail/${musicId}`
  )}&title=${encodeURIComponent(`Check out ${name} on Womucon!`)}`
  window.open(linkedInUrl, "_blank")
  await recordShare({ musicId, destination: "LinkedIn" })
}

// Function to share via Email
Share.shareToEmail = async (musicId: string, name: string) => {
  const subject = `Check out ${name} on Womucon!`
  const body = `I found this amazing track on Womucon: ${name}. Check it out here: ${config.appUrl}/musicDetail/${musicId}`
  const mailToLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
    body
  )}`
  window.open(mailToLink, "_blank")
  await recordShare({ musicId, destination: "Email" })
}

export default Share
