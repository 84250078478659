import React from "react"
import { Box, Container, SxProps, Theme } from "@mui/material"

interface PageContainerProps {
  children: React.ReactNode
  sx?: SxProps<Theme>
}

/**
 * Common page container for wrapping page content
 */
export default function PageContainer({ children, sx }: PageContainerProps) {
  return (
    <Container maxWidth="xl" sx={sx}>
      <Box py={3}>{children}</Box>
    </Container>
  )
}
