import React from "react"
import { useNavigate } from "react-router-dom"
import { PageTitle, Page, PageContainer } from "../../component"
import { UploadSongFormStateProps } from "../../types"
import { ProjectAPI } from "../../api"
import { useNotification } from "../../context/NotificationProvider"
import { UploadSongFormInitiateState } from "../UploadSong/uploadSongData"
import ProjectStepper from "../StartProject/ProjectStepper"
import UploadSongForm from "../../component/UploadSongForm/UploadSongForm"
import UploadSongPreview from "./UploadSongPreview"
import UploadSongInfo from "./UploadSongInfo"
import _ from "lodash"

/**
 * Upload song page
 */
export default function UploadSongPage() {
  const navigate = useNavigate()
  const { openNotification } = useNotification()

  const steps = ["1", "2"]

  const [uploadSongForm, setUploadSongForm] = React.useState<UploadSongFormStateProps>(
    UploadSongFormInitiateState
  )
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState<string>("") // for image preview only
  const [uploadMusicFile, setUploadMusicFile] = React.useState<File | null>(null) // for direct File upload
  const [uploadImage, setUploadImage] = React.useState<File | null>(null) // for direct File upload
  const [agreement, setAgreement] = React.useState<boolean>(false)

  const handleFormSubmit = async () => {
    const { res, error } = await ProjectAPI.createProject(uploadSongForm)

    if (error) openNotification("ERROR", error.message)

    if (uploadImage) await ProjectAPI.uploadProjectImage(res?._id, uploadImage)

    if (uploadMusicFile) await ProjectAPI.finishProject(res?._id, uploadMusicFile)

    location.assign(`/projects/${res?._id}`)
  }

  const onCancel = () => navigate("/marketPlace")

  // Check form validation
  const musicTypeCheck = (musicType: string) => {
    if (musicType === "STANDARD") return true
    if (musicType === "PREMIUM")
      return Boolean(uploadSongForm?.currency) && Boolean(uploadSongForm?.price)
    return false
  }
  const extraMusicTypeValidation = musicTypeCheck(uploadSongForm?.musicType)

  const isFormValid =
    Boolean(uploadMusicFile) &&
    Boolean(uploadSongForm?.name) &&
    Boolean(uploadSongForm?.description) &&
    Boolean(!_.isEmpty(uploadSongForm?.instruments)) &&
    Boolean(!_.isEmpty(uploadSongForm?.styles)) &&
    Boolean(uploadSongForm?.tempo) &&
    Boolean(uploadSongForm?.musicKey) &&
    Boolean(uploadSongForm?.metreUpper) &&
    Boolean(uploadSongForm?.metreLower) &&
    Boolean(uploadSongForm?.musicType) &&
    extraMusicTypeValidation

  return (
    <Page pageId={UploadSongPage} noPadding>
      <PageContainer>
        <PageTitle title={"Upload Your Song"} sx={{ textAlign: "center", my: 4 }} />
        <ProjectStepper
          steps={steps}
          isFormValid={isFormValid}
          isAgreementValid={agreement}
          onSubmit={handleFormSubmit}
          onCancel={onCancel}
          components={[
            <UploadSongForm
              key={1}
              onSubmit={() => {}}
              formState={uploadSongForm}
              setFormState={setUploadSongForm}
              handleUploadMusicFile={(file: File | null) => setUploadMusicFile(file)}
              handleUploadImage={(file: File | null) => setUploadImage(file)}
              imagePreviewUrl={imagePreviewUrl} // for image preview only
              setImagePreviewUrl={setImagePreviewUrl} // for image preview only
            />,
            <UploadSongPreview
              key={2}
              agreement={agreement}
              onAgreementChange={() => setAgreement(!agreement)}
            >
              <UploadSongInfo {...uploadSongForm} imagePreviewUrl={imagePreviewUrl} />
            </UploadSongPreview>,
          ]}
        />
      </PageContainer>
    </Page>
  )
}
