import { Dispatch, SetStateAction } from "react"
import { Box, Button } from "@mui/material"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { FilterState } from "../../../types/marketPlace"
import useMUIMediaQuery from "../../../hooks/useMUIMedia"

const SORT_BUTTONS_CONFIG = [
  { type: "latest", label: "The latest", key: "uploadTimestamp" },
  { type: "popular", label: "Most popular", key: "tbc1" },
  { type: "follow", label: "Following", key: "tbc2" },
  { type: "favorite", label: "Favorited", key: "tbc3" },
]

/**
 * Sort menu in market place
 */
export default function MarketPlaceSortMenu({
  filter,
  setFilter,
}: {
  filter: FilterState
  setFilter: Dispatch<SetStateAction<FilterState>>
}) {
  const { isMobile } = useMUIMediaQuery()

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1, mb: 2 }}>
      {SORT_BUTTONS_CONFIG.map(({ type, label, key }) => {
        const isCurrentSortKey = filter?.sortBy === key
        return (
          <Button
            size={isMobile ? "small" : "medium"}
            key={`sort_${type}`}
            variant="outlined"
            sx={{ textTransform: "none" }}
            onClick={() => {
              const newSortOrder = filter?.sortOrder === "DESC" ? "ASC" : "DESC"
              setFilter((prevFilter) => {
                return { ...prevFilter, sortBy: key, sortOrder: newSortOrder }
              })
            }}
          >
            {label}
            {!isCurrentSortKey ? (
              <></>
            ) : filter?.sortOrder === "DESC" ? (
              <ArrowDropDownIcon />
            ) : (
              <ArrowDropUpIcon />
            )}
          </Button>
        )
      })}
    </Box>
  )
}
