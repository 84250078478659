import React from "react"
import ReactCrop, { Crop, PixelCrop } from "react-image-crop"
import { canvasPreview, imgPreviewToFile } from "../ImageCrop/ImageCropData"
import { Button, Stack } from "@mui/material"
import useMUIMediaQuery from "../../hooks/useMUIMedia"

interface CoverImageCropProps {
  imgSrc: string
  handleSaveImage: (_url: string, _file: File) => void
}

/**
 * Cover Image Corp
 */
export default function CoverImageCrop({ imgSrc, handleSaveImage }: CoverImageCropProps) {
  const { isMobile } = useMUIMediaQuery()
  const imgRef = React.useRef<HTMLImageElement>(null)
  const defaultCrop: PixelCrop = { x: 20, y: 0, width: 147, height: 30, unit: "px" }
  const [crop, setCrop] = React.useState<Crop>(defaultCrop)
  const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>(defaultCrop)

  let maxWidth = "100vw"
  let maxHeight = "100vh"

  if (isMobile) {
    maxWidth = "100vw"
    maxHeight = "100vh"
  }

  const saveImage = async () => {
    if (!imgRef.current || !crop || !completedCrop) return

    const canvas = document.createElement("canvas")
    canvasPreview(imgRef.current, canvas, completedCrop)

    const file = await imgPreviewToFile(canvas)
    if (!file) return

    const url = URL.createObjectURL(file)
    handleSaveImage(url, file)
  }

  const handleCropImage = (c: PixelCrop) => {
    setCrop(c)
    setCompletedCrop(c)
  }

  return (
    <>
      <Stack maxWidth={maxWidth} maxHeight={maxHeight}>
        <ReactCrop crop={crop} onChange={handleCropImage} aspect={14.7 / 3}>
          <img ref={imgRef} alt="upload-img" src={imgSrc} width="100%" />
        </ReactCrop>
        <Button variant="outlined" onClick={() => saveImage()}>
          Save
        </Button>
      </Stack>
    </>
  )
}
