import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

interface ProjectStepperProps {
  isFormValid: boolean
  isAgreementValid: boolean
  components: React.ReactNode[]
  steps: Array<string>
  onCancel: () => void
  onSubmit: () => void
}

/**
 * Project stepper - containing Upload screen and Preview screen
 */
export default function ProjectStepper({
  isFormValid,
  isAgreementValid,
  onSubmit,
  components,
  steps,
  onCancel,
}: ProjectStepperProps) {
  const [activeStep, setActiveStep] = React.useState(0)
  const isLastPage = activeStep === steps.length - 1
  const isFirstPage = activeStep === 0

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
    onCancel()
  }

  const handleSubmit = () => onSubmit()

  // Check progress valid
  const checkIsProgressValid = () => {
    if (activeStep === 0 && isFormValid) return true
    if (activeStep === 1 && isAgreementValid) return true
    return false
  }
  const isValid = checkIsProgressValid()

  return (
    <Box sx={{ width: "100%" }}>
      <React.Fragment>
        {components[activeStep]}
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Box sx={{ flex: "1 1 auto" }} />
          {isFirstPage ? (
            <Button color="primary" variant="outlined" onClick={handleReset} sx={{ mr: 1 }}>
              Cancel
            </Button>
          ) : (
            <Button color="primary" variant="outlined" onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
          )}
          <Button
            disabled={!isValid}
            onClick={isLastPage ? handleSubmit : handleNext}
            variant="contained"
            endIcon={<ArrowForwardIcon />}
          >
            {isLastPage ? "Publish" : "Next"}
          </Button>
        </Box>
      </React.Fragment>
    </Box>
  )
}
