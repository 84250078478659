import React from "react"
import { Avatar, FormControlLabel, Checkbox, Typography } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import { renderIcon } from "./MusicStyleList/musicStyleData"

interface ImageCheckBoxProps {
  label: string
  value: string
  image?: string
  // eslint-disable-next-line no-unused-vars
  handleCheckBoxToggleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  selectedItems: string[]
}

const ICON_SIZE = 35

/**
 * A clickable, listitem like checkbox
 */
export default function ImageCheckBox({
  label,
  value,
  image,
  handleCheckBoxToggleChange,
  selectedItems,
}: ImageCheckBoxProps) {
  return (
    <>
      <FormControlLabel
        //@ts-ignore
        onChange={(event) => handleCheckBoxToggleChange(event)}
        labelPlacement="start"
        control={
          <Checkbox
            data-testid={`img-${value}`}
            value={value}
            sx={{
              visibility: selectedItems.includes(value) ? "visible" : "hidden",
            }}
            checked={selectedItems.includes(value)}
            style={{ zIndex: 0 }}
            checkedIcon={<CheckIcon />}
          />
        }
        sx={{ flex: 1, ml: 1, mr: 0 }}
        label={
          <>
            {image ? (
              <img src={renderIcon(image)} alt="thumbnail" width={ICON_SIZE} height={ICON_SIZE} />
            ) : (
              <Avatar
                variant="square"
                alt={value}
                sx={{
                  width: ICON_SIZE,
                  height: ICON_SIZE,
                }}
              />
            )}
            <Typography sx={{ ml: 1 }}>{label}</Typography>
          </>
        }
      />
    </>
  )
}
