import React, { useCallback } from "react"
import { Grid, Button, Typography, SelectChangeEvent, FormLabel } from "@mui/material"
import update from "immutability-helper"
import { InstrumentExpItem } from "../../component"
import { InstrumentExperienceProps } from "../../types/userTypes"
import uniqueId from "lodash/uniqueId"
import isEqual from "lodash/isEqual"

interface UserInstrumentProps {
  instruments: InstrumentExperienceProps[]
  disabled?: boolean
  handleUpdateInstrument: (_: InstrumentExperienceProps[]) => void
}

function UserInstrument({
  instruments,
  disabled = false,
  handleUpdateInstrument,
}: UserInstrumentProps) {
  const disableDelete = instruments.length === 1
  const handleUpdateInstrumentList = (instruments: any, existingIndex: any, instrument: any) => {
    return update(instruments, {
      [existingIndex]: {
        $set: instrument,
      },
    })
  }

  const handleAddNewInstrument = () => {
    handleUpdateInstrument([
      ...instruments,
      {
        type: "",
        year: "1",
      },
    ])
  }

  const findInstrumentIndex = useCallback(
    (selectedInstrument: InstrumentExperienceProps[], instrumentObj: InstrumentExperienceProps) => {
      const newInstrumentIndex = selectedInstrument.length - 1
      const existingIndex = instruments.findIndex((inst) => inst.type === instrumentObj.type)
      return { newInstrumentIndex, existingIndex }
    },
    [instruments]
  )

  const handleSelectInstrument = useCallback(
    (selectedInstrument: SelectChangeEvent<string>, instrumentObj: InstrumentExperienceProps) => {
      // Find the index of the changed instrument for mutation
      const { existingIndex } = findInstrumentIndex(instruments, instrumentObj)
      const instrument = {
        ...instrumentObj,
        type: selectedInstrument.target.value.toLowerCase(),
      }
      const updatedInstruments = handleUpdateInstrumentList(instruments, existingIndex, instrument)
      handleUpdateInstrument(updatedInstruments)
    },
    [findInstrumentIndex, handleUpdateInstrument, instruments]
  )

  const handleYearChange = useCallback(
    (e: any, instrumentObj: InstrumentExperienceProps) => {
      const year = e.target.value
      const { existingIndex } = findInstrumentIndex([], instrumentObj)
      const instrument = { ...instrumentObj, year }
      const updatedInstruments = handleUpdateInstrumentList(instruments, existingIndex, instrument)
      handleUpdateInstrument(updatedInstruments)
    },
    [findInstrumentIndex, handleUpdateInstrument, instruments]
  )

  const handleRemoveInstrument = useCallback(
    (type: string) => {
      handleUpdateInstrument(instruments.filter((instrument) => instrument.type !== type))
    },
    [handleUpdateInstrument, instruments]
  )

  const renderInstrument = useCallback(() => {
    return instruments.map((instrument, i) => (
      <InstrumentExpItem
        key={instrument.type + i}
        instrument={instrument}
        instrumentIndex={i}
        disabled={false}
        disableDelete={disableDelete}
        handleYearChange={handleYearChange}
        handleSelectInstrument={handleSelectInstrument}
        handleRemoveInstrument={handleRemoveInstrument}
      />
    ))
  }, [instruments, disableDelete, handleRemoveInstrument, handleSelectInstrument, handleYearChange])

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          "& .MuiTextField-root": { my: 1 },
          "& .MuiFormControl-root": { my: 1 },
        }}
        alignItems="center"
      >
        <Grid item xs={12}>
          <FormLabel component="legend">Instruments</FormLabel>
        </Grid>
        {renderInstrument()}
      </Grid>
      <Grid container item xs={12} spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Button
            onClick={handleAddNewInstrument}
            variant="outlined"
            color="primary"
            fullWidth
            size="large"
            sx={{
              borderStyle: "dashed",
            }}
          >
            Add Instrument
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default UserInstrument
