import React, { useEffect } from "react"
import { Box, Grid } from "@mui/material"
import { MusicAPI, SavedMusicAPI } from "../../api"
import MarketPlaceTable from "../../component/MarketPlace/Table/MarketPlaceTable"

/**
 * Saved Music Tab
 */
export default function SavedMusic() {
  const [savedMusics, setSavedMusics] = React.useState([])
  const [, setError] = React.useState("")

  useEffect(() => {
    const getSavedMusics = async () => {
      const response = await SavedMusicAPI.getSavedMusic()
      if (response?.err) {
        setError(response?.err)
        return
      }
      setSavedMusics(response?.res)
    }
    getSavedMusics()
  }, [])

  console.log(savedMusics)

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container columnSpacing={2}>
        <Grid item xs={10}>
          <MarketPlaceTable data={savedMusics?.map((t: any) => t.musicId)} />
        </Grid>
      </Grid>
    </Box>
  )
}
