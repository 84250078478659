import React from "react"
import { Box, Grid } from "@mui/material"
import { MarketPlaceSortMenu, MarketPlaceTable } from "../../../component"
import { MusicAPI } from "../../../api"
import { FilterState } from "../../../types/marketPlace"

/**
 * SoundEffect tab in market place page
 */
export default function SoundEffect() {
  const [marketPlaceData, setMarketPlaceData] = React.useState([])
  const [, setError] = React.useState("")

  // Filtering and sorting state for getMarketPlaceData
  const [filter, setFilter] = React.useState<FilterState>({
    style: [],
    sortBy: "uploadTimestamp",
    sortOrder: "DESC",
  })

  React.useEffect(() => {
    const getMarketPlaceData = async () => {
      const response = await MusicAPI.retrieve({
        pageIndex: 0,
        pageSize: 0,
        styles: filter?.style,
        sortBy: filter?.sortBy,
        sortOrder: filter?.sortOrder,
        musicType: "ALL",
      })
      if (response?.err) {
        setError(response?.err)
        return
      }
      setMarketPlaceData(response?.res)
    }
    getMarketPlaceData()
  }, [filter])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        <Grid item xs={12}>
          <MarketPlaceSortMenu filter={filter} setFilter={setFilter} />
          <MarketPlaceTable data={marketPlaceData} />
        </Grid>
      </Grid>
    </Box>
  )
}
