import * as React from "react"
import {
  SelectChangeEvent,
  Grid,
  TextField,
  Typography,
  Slider,
  Button,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material"
import { musicKeys, tempoMarks } from "../../pages/StartProject/projectData"
import { Dropdown, ImageCrop, Modal } from "../../component"
import { Box } from "@mui/system"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import { MUSIC_TYPES } from "../../pages/UploadSong/uploadSongData"

interface ProjectBasicInfoProps {
  handleUploadImage: (_: File | null) => void
  handleSelectChange: (_: SelectChangeEvent<string>) => void
  handleMetreChange: (_: SelectChangeEvent<string>) => void
  handleTextChange: (_: React.ChangeEvent<HTMLInputElement>) => void
  handleNumberChange: (_: React.ChangeEvent<HTMLInputElement>) => void
  handleTempoChange: (_: number) => void
  handleMusicTypeChange: (_: string) => void
  disabled?: boolean
  name: string
  description: string
  tempo: number
  metreUpper: number
  metreLower: number
  musicKey: string
  musicType: string
  currency: string
  price: string
  imagePreviewUrl: string
  setImagePreviewUrl: (_: string) => void
}

/**
 * Upload song basic info
 */
export default function UploadSongBasicInfo({
  handleUploadImage,
  handleSelectChange,
  handleTextChange,
  handleNumberChange,
  handleTempoChange,
  handleMetreChange,
  handleMusicTypeChange,
  disabled = false,
  name,
  description,
  tempo,
  metreUpper,
  metreLower,
  musicKey,
  musicType,
  currency,
  price,
  imagePreviewUrl, // for image preview only
  setImagePreviewUrl, // for image preview only
}: ProjectBasicInfoProps) {
  const profileImgInputRef = React.useRef<HTMLInputElement>(null)
  const [open, setOpen] = React.useState(false)

  const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files && e?.target?.files?.length > 0) {
      setOpen(true)
      const reader = new FileReader()
      reader?.addEventListener("load", () => setImagePreviewUrl(reader?.result?.toString() || ""))
      reader?.readAsDataURL(e?.target?.files?.[0])
    }
  }

  const handleSaveImage = (url: string, file: File) => {
    setImagePreviewUrl(url)
    handleUploadImage(file)
    setOpen(false)
  }

  return (
    <Grid
      container
      spacing={{ xs: 2, lg: 6 }}
      sx={{
        mt: 2,
        mb: 6,
        "& .MuiTextField-root": { mb: 2 },
        "& .MuiFormControl-root": { my: 2 },
      }}
    >
      <Modal open={open} handleToggleClose={() => setOpen(false)}>
        <Stack>
          <ImageCrop imgSrc={imagePreviewUrl} handleSaveImage={handleSaveImage} />
        </Stack>
      </Modal>
      <Grid item xs={12} lg={2}>
        <Box sx={{ position: "relative", mt: 2 }}>
          <input
            ref={profileImgInputRef}
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            onChange={handleAddImage}
            accept={"image/*"}
          />
          <label htmlFor="contained-button-file">
            <Button
              component="span"
              sx={{
                width: "100%",
                height: imagePreviewUrl ? "100%" : 200,
                borderStyle: "dashed",
                borderWidth: 5,
                p: 1,
                textAlign: "center",
              }}
            >
              {imagePreviewUrl ? (
                <img src={imagePreviewUrl} alt="imagePreview" width="100%" />
              ) : (
                <Box sx={{ alignItems: "center" }}>
                  <AddCircleOutlineIcon sx={{ width: 40, height: 40 }} />
                  <Typography>Project / Music Thumbnail</Typography>
                </Box>
              )}
            </Button>
          </label>
        </Box>
      </Grid>

      <Grid item xs={12} lg={5}>
        <TextField
          required
          label="Title"
          fullWidth
          name="name"
          value={name}
          onChange={handleTextChange}
          disabled={disabled}
        />
        <TextField
          required
          label="Description"
          fullWidth
          name="description"
          value={description}
          onChange={handleTextChange}
          disabled={disabled}
          multiline
          rows={4}
        />
        <Typography sx={{ my: 1 }}>Music Type</Typography>
        <ToggleButtonGroup
          exclusive
          value={musicType}
          color="primary"
          orientation={"horizontal"}
          onChange={(_, value) => handleMusicTypeChange(value)}
          sx={{ gap: 4 }}
        >
          {MUSIC_TYPES.map((item) => (
            <MusicTypeToggleButton key={item} value={item} />
          ))}
        </ToggleButtonGroup>

        {musicType === "PREMIUM" && (
          <Stack
            direction={{ xs: "column", lg: "row" }}
            spacing={4}
            sx={{ my: 1, mb: -2 }} // compensate last component row margin bottom
          >
            <Box flex={1}>
              <Dropdown
                value={currency}
                label="Currency"
                onChange={handleSelectChange}
                options={["HKD"]}
                name="currency"
                disabled={disabled}
              />
            </Box>
            <TextField
              required={musicType === "PREMIUM"}
              label="Price"
              fullWidth
              name="price"
              value={price}
              onChange={handleNumberChange}
              disabled={disabled}
              sx={{ flex: 1 }}
            />
          </Stack>
        )}
      </Grid>

      <Grid item xs={12} lg={5}>
        <Dropdown
          value={musicKey}
          label="Music Key"
          onChange={handleSelectChange}
          options={musicKeys}
          name="musicKey"
          disabled={disabled}
        />

        <Typography>Tempo</Typography>
        <Grid container item xs={12}>
          <Stack direction="row" alignItems="center" flex={1} spacing={4}>
            <Slider
              value={tempo}
              onChange={(e, value) => handleTempoChange(value as number)}
              min={60}
              max={160}
              marks={tempoMarks}
              valueLabelFormat={() => String(tempo)}
              sx={{ flex: 3, m: 0 }}
            />
            <TextField
              required
              variant="standard"
              fullWidth
              name="tempo"
              value={tempo}
              onChange={handleTextChange}
              disabled={disabled}
              type="number"
              sx={{ flex: 1 }}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} spacing={2}>
          {/* <Grid item xs={12}>
            <Typography>Metre</Typography>
          </Grid> */}
          <Grid item xs={12} lg={12}>
            <Dropdown
              value={`${metreUpper}/${metreLower}`}
              label="Metre"
              onChange={handleMetreChange}
              options={[
                "1/4",
                "2/4",
                "3/4",
                "4/4",
                "3/8",
                "6/8",
                "9/8",
                "12/8",
                `${metreUpper}/${metreLower}`,
              ]}
              name="Metre"
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

/**
 * Toggle button for music type selection
 */
// eslint-disable-next-line react/no-multi-comp
function MusicTypeToggleButton(props: any) {
  let label = "--"
  if (props.value === "PREMIUM") label = "Premium"
  if (props.value === "STANDARD") label = "Standard"

  return (
    <Grid item xs={12}>
      <ToggleButton {...props} size="small" sx={{ minWidth: 160 }}>
        {label}
      </ToggleButton>
    </Grid>
  )
}
