import React from "react"
import { Grid, TextField, Button } from "@mui/material"
import { postRequest } from "../../api/request"
import { Page, PageContainer, PageTitle } from "../../component"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

/**
 * Contact Us Form Component
 */
function ContactUs() {
  const [message, setMessage] = React.useState("")
  const [notificationText, setNotificationText] = React.useState("")
  const handleTextChange = (e: { target: { value: React.SetStateAction<string> } }) =>
    setMessage(e.target.value)

  const handleFormSubmit = async () => {
    try {
      postRequest({
        url: "/admin/contactus",
        data: {
          message,
        },
      })
      setNotificationText("")
      setMessage("")
      setNotificationText(
        "Thank you, we will review your question and get back to you as soon as possible"
      )
    } catch (err) {
      console.log("err:", err)
      setNotificationText("Something went wrong, please try again")
    }
  }
  return (
    <Page pageId={ContactUs} noPadding>
      <PageContainer>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PageTitle title="Please type your question below" />
          </Grid>
          <Grid item xs={12}>
            <TextField
              key="description"
              id="description"
              label="Description"
              // margin="normal"
              name="description"
              multiline
              fullWidth={true}
              rows={10}
              value={message}
              onChange={handleTextChange}
              variant="outlined"
              helperText={notificationText ? notificationText : ""}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleFormSubmit}
              disabled={!message}
              endIcon={<ArrowForwardIcon />}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </PageContainer>
    </Page>
  )
}

export default ContactUs
