import React, { useMemo } from "react"
import {
  ListItem,
  ListItemText,
  Collapse,
  Chip,
  ListItemAvatar,
  Divider,
  Typography,
  Box,
} from "@mui/material"
import ExpandMore from "@mui/icons-material/ExpandMore"
import ExpandLess from "@mui/icons-material/ExpandLess"
import { InstrumentCategoriesProps, renderIcon } from "./instrumentData"

interface InstrumentItemProps {
  handleAddInstrument: (_selected: string) => void
  handleRemoveInstrument: (_selected: string) => void
  instrument: InstrumentCategoriesProps
  selectedInstruments: string[]
}

const ICON_SIZE = 40

export const InstrumentItem = ({
  instrument,
  handleAddInstrument,
  handleRemoveInstrument,
  selectedInstruments,
}: InstrumentItemProps) => {
  const [selectedPanel, setSelectedPanel] = React.useState<string | null>(null)
  const [selectedNumber, setSelectedNumber] = React.useState(0)
  const { label } = instrument
  const open = label === selectedPanel

  const options = useMemo(
    () =>
      instrument?.options
        ? instrument.options
        : [
            {
              label,
              value: label,
            },
          ],
    [instrument.options, label]
  )

  React.useEffect(() => {
    let count = 0
    options.forEach((opt) => {
      if (selectedInstruments.includes(opt.value.toLowerCase())) {
        count++
      }
    })
    setSelectedNumber(count)
  }, [options, selectedInstruments])

  /* To control which, if any, of the panel is selected in parent state */
  const handleClick = () => {
    setSelectedPanel(open ? null : label)
  }

  const renderChip = (option: { label: string; value: string }) => {
    const value = option.value.toLowerCase()
    if (selectedInstruments.includes(value)) {
      return (
        <Chip
          key={value}
          label={option.label}
          color="primary"
          variant="outlined"
          onClick={() => handleRemoveInstrument(value)}
          onDelete={() => handleRemoveInstrument(value)}
          sx={{ justifySelf: "flex-start" }}
        />
      )
    }
    return (
      <Chip
        key={value}
        label={option.label}
        color="primary"
        variant="outlined"
        onClick={() => handleAddInstrument(value)}
      />
    )
  }

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemAvatar>
          <img
            src={renderIcon(instrument?.label)}
            alt="thumbnail"
            width={ICON_SIZE}
            height={ICON_SIZE}
            style={{ marginTop: 4 }} // compensate display for src image limitation
          />
        </ListItemAvatar>
        <ListItemText
          primary={label}
          secondary={
            <Typography color="primary">
              {selectedNumber ? `${selectedNumber} Instrument Selected` : ""}
            </Typography>
          }
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
          component="ul"
        >
          {options.map((option) => renderChip(option))}
        </Box>
      </Collapse>
      <Divider variant="inset" component="li" />
    </>
  )
}
