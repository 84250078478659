import { getRequest, postRequest } from "./request"

const Cart: any = {}

Cart.getCart = async () => {
  return await getRequest({ url: `/cart/cart` })
}

Cart.addToCart = async (musicId: string) => {
  return await postRequest({ url: `/cart/add/${musicId}` })
}

Cart.removeFromCart = async (musicId: string) => {
  const params = {
    method: "DELETE",
  }
  return await postRequest({ url: `/cart/remove/${musicId}`, params })
}

Cart.checkout = async (paymentMethodId: string) => {
  const data = { paymentMethodId }
  return await postRequest({ url: `/cart/checkout`, data })
}

export default Cart
