import React from "react"
import { SxProps, Theme, Typography } from "@mui/material"

interface PageTitleProps {
  title: string
  sx?: SxProps<Theme>
}

/**
 * Page Title Component
 */
export default function PageTitle({ title, sx }: PageTitleProps) {
  return (
    <Typography gutterBottom variant="h4" fontWeight={600} sx={sx}>
      {title}
    </Typography>
  )
}
