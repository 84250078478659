import { Grid, Avatar, Typography, IconButton, Stack } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"

interface AvatarStateProps {
  bodyTxt: string
  userUrl?: string
  name?: string
  imgUrl?: string
  src?: string
  subTxtTop?: string
  subTxtBt?: string
  split?: number[]
  children?: React.ReactNode
  replyComponent?: React.ReactNode
}

/**
 * Mui Avatar
 */
export default function MuiAvatar(props: AvatarStateProps) {
  const {
    userUrl = "",
    name,
    imgUrl,
    bodyTxt,
    subTxtTop,
    subTxtBt,
    children,
    split = [2, 3, 7],
    replyComponent,
  } = props

  return (
    <Grid container alignItems={"center"}>
      <Grid item xs={split[0]}>
        <Stack sx={{ alignItems: "center" }}>
          <IconButton>
            <Link to={userUrl}>
              <Avatar alt={name} src={imgUrl} />
            </Link>
          </IconButton>
        </Stack>
      </Grid>
      <Grid item xs={split[1]}>
        <Typography variant="caption">{subTxtTop}</Typography>
        <Typography variant="body1">{bodyTxt}</Typography>
        <Typography variant="caption">{subTxtBt}</Typography>
      </Grid>
      <Grid item xs={split[2]}>
        {children}
      </Grid>
      {replyComponent}
    </Grid>
  )
}
