import React from "react"
import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import { Link } from "react-router-dom"
import MailIcon from "@mui/icons-material/Mail"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import FolderSharedIcon from "@mui/icons-material/FolderShared"
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic"
// import HeadphonesIcon from "@mui/icons-material/Headphones"
import StorefrontIcon from "@mui/icons-material/Storefront"
import Button from "@mui/material/Button"
import NavItem from "../NavItem"
import { useUserInfo } from "../../context"

/**
 * Drawer Menus
 */
export default function DrawerMenu() {
  const { userToken } = useUserInfo()
  const isLoggedIn = Boolean(userToken)

  const items = [
    {
      href: "/marketPlace",
      icon: StorefrontIcon,
      title: "Market Place",
    },
    {
      href: "/user/self/projects",
      icon: FolderSharedIcon,
      title: "My Projects",
      divider: true,
    },
    {
      href: "/projects",
      icon: LibraryMusicIcon,
      title: "Projects Center",
    },
    // {
    //   href: "/music",
    //   icon: HeadphonesIcon,
    //   title: "Browse Music",
    // },
    {
      href: "/contactus",
      icon: MailIcon,
      title: "Contact Us",
      divider: true,
    },
  ]

  return (
    <div>
      <Divider />

      <List>
        {items?.map(({ href, title, icon, divider }) => (
          <React.Fragment key={title}>
            {divider && <Divider />}
            <NavItem href={href} key={title} title={title} icon={icon} />
          </React.Fragment>
        ))}
      </List>

      <Button
        disabled={!isLoggedIn}
        onClick={() => {}}
        component={Link}
        to="/startProject"
        type="submit"
        color={"primary"}
        variant="contained"
        sx={{
          position: "absolute",
          left: 20,
          right: 20,
          bottom: "5vh",
          borderRadius: 30,
          pb: 1.5,
          pt: 1.5,
        }}
        endIcon={<ArrowForwardIcon />}
      >
        Start Project
      </Button>
    </div>
  )
}
