import React from "react"
import { Box, Grid, Typography, IconButton, Button } from "@mui/material"
import CancelIcon from "@mui/icons-material/Cancel"
import { colors } from "../../theme/colors"

type AcknowledgementItemProps = {
  title: string
  artist: string
  price: number
  format: string
  imageUrl?: string
}

/**
 AcknowledgementItem component
 */
export default function AcknowledgementItem({
  title,
  artist,
  price,
  format,
  imageUrl,
}: AcknowledgementItemProps) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: 2,
        marginBottom: 2,
        border: `1px solid ${colors.grey}`,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
        <img
          src={imageUrl}
          alt={`${title} cover`}
          style={{ width: 50, height: 50, marginRight: 16 }}
        />
        <Box>
          <Typography variant="subtitle1" sx={{ color: "#fff" }}>
            {title}
          </Typography>
          <Typography variant="body2" sx={{ color: colors.grey }}>
            {artist}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2" sx={{ color: colors.grey, marginRight: 16 }}>
          Format: {format}
        </Typography>
        <Typography variant="body1" bgcolor={colors.grey3} sx={{ color: "#fff", p: 1 }}>
          HKD {price}
        </Typography>
      </Box>
    </Box>
  )
}
