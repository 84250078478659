import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  Radio,
  Typography,
  Button,
  Stack,
} from "@mui/material"
import { Modal } from "../../component"
import Dropzone from "react-dropzone-uploader"
import { colors, uploadSectionContainerStyle } from "../../theme/colors"
import React from "react"
import { Link } from "react-router-dom"
import { acceptMusicFileExt, acceptDocFileExt } from "../../types/uploadFileTypes"

const containerStyle = {
  " & h5": {
    color: colors.teal,
  },
  " & h6": {
    color: colors.grey,
  },
}

type UploadSectionStateProps = {
  _id?: string
  instruments?: string[]
  handleUploadInstrumentSheet: (_instrument: string, _file: File | undefined) => void
  handleUploadInstrumentMusic: (_instrument: string, _file: File | undefined) => void
}

/**
 * Project Detail Upload File Section
 */
export default function UploadSection(props: UploadSectionStateProps) {
  const {
    _id: projectId,
    instruments = [],
    handleUploadInstrumentSheet,
    handleUploadInstrumentMusic,
  } = props
  const [selectedValue, setSelectedValue] = React.useState("")
  const [uploadFile, setUploadFile] = React.useState<undefined | File>(undefined)
  const [showModal, setShowModal] = React.useState(false)
  const [selectedInstrument, setSelectedInstrument] = React.useState<string>(instruments[0])

  const handleCloseModal = () => {
    setShowModal(false)
    setSelectedValue("")
  }

  const handleSelectInstrument = (e: any) => {
    setSelectedInstrument(e?.target?.value)
  }

  const handleUploadSelection = (e: any) => {
    setShowModal(true)
    setSelectedValue(e?.target?.value)
  }

  const handleFileDrop = (fileDropParams: { meta: any; file: File }, status: string) => {
    const { file } = fileDropParams
    if (status === "done") {
      setUploadFile(file)
    } else if (status === "removed") {
      setUploadFile(undefined)
    }
  }

  const handleSubmitFile = () => {
    if (selectedValue === "Sheet") {
      handleUploadInstrumentSheet(selectedInstrument, uploadFile)
    } else if (selectedValue === "File") {
      handleUploadInstrumentMusic(selectedInstrument, uploadFile)
    }

    handleCloseModal()
  }

  const renderInstrumentGroup = () => {
    return instruments?.map((instrument) => (
      <FormControlLabel
        key={instrument}
        value={instrument}
        control={<Radio />}
        label={instrument}
      />
    ))
  }
  return (
    <Grid container sx={containerStyle}>
      <Grid item xs={12}>
        <Typography variant="h5">Upload</Typography>
        <Stack direction={"row"} spacing={2} alignItems="center" paddingTop={1} paddingBottom={4}>
          <Link
            to={`/projects/${projectId}/edit`}
            style={{ textDecoration: "none", color: colors.orange }}
          >
            <Button color="primary" variant="outlined" sx={{ minWidth: 150, minHeight: 40 }}>
              Edit
            </Button>
          </Link>
          <FormControl sx={{ minWidth: 150 }} size="small">
            <InputLabel id="project-upload-select-type" sx={{ color: colors.orange }}>
              UPLOAD
            </InputLabel>
            <Select
              labelId="project-upload-select-type"
              id="project-upload-select-type-c"
              label="Upload"
              value={selectedValue}
              onChange={handleUploadSelection}
            >
              <MenuItem value={"Sheet"}>Music Sheet</MenuItem>
              <MenuItem value={"File"}>Music File</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        <Modal open={showModal} handleToggleClose={handleCloseModal}>
          <Grid container>
            <Grid item xs={12} sx={{ padding: 3 }}>
              <Typography variant="h5" textAlign="center">
                Upload Music {selectedValue}
              </Typography>
              <FormControl>
                <FormLabel id="project-upload-instrument-type-label">Select Instrument</FormLabel>
                <RadioGroup
                  aria-labelledby="project-upload-instrument-type-label"
                  value={selectedInstrument}
                  name="radio-buttons-group"
                  onChange={handleSelectInstrument}
                >
                  {renderInstrumentGroup()}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={uploadSectionContainerStyle}>
              <Dropzone
                accept={selectedValue === "Sheet" ? acceptDocFileExt : acceptMusicFileExt}
                multiple={false}
                maxFiles={1}
                inputContent="Upload File"
                onChangeStatus={handleFileDrop}
                onSubmit={handleSubmitFile}
              />
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </Grid>
  )
}
