import { Box, Container } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { colors } from "../../theme/colors"
import WaveSurfer from "wavesurfer.js"

interface WaveMusicPlayerProps {
  audioUrl: string
  zoom?: number
  getWaveSurferInstance?: (waveSurfer: WaveSurfer) => void
  containerStyles?: React.CSSProperties
  playingAudio: boolean
  waveformHeight: number
  setDuration?: (duration: number) => void
  setCurrentTime?: (currentTime: number) => void
  volume: number
}

/**
 * Wave Music Player
 */
export default function WaveMusicPlayer(props: WaveMusicPlayerProps) {
  const { setCurrentTime, setDuration } = props

  const waveformRef = useRef<HTMLDivElement | null>(null)
  const trackRef = useRef<HTMLAudioElement | null>(null)
  const [waveSurfer, setWaveSurfer] = useState<WaveSurfer | null>(null)
  const playAudio = () => {
    if (waveSurfer) waveSurfer.play()
  }

  const pauseAudio = () => {
    if (waveSurfer) waveSurfer.pause()
  }

  useEffect(() => {
    if (props.playingAudio) {
      playAudio()
    } else {
      pauseAudio()
    }
  })

  useEffect(() => {
    if (waveformRef.current && trackRef.current) {
      const wavesurfer = WaveSurfer.create({
        container: "#waveform",
        backend: "MediaElement",
        height: props.waveformHeight || 100,
        waveColor: "#FFFFFF",
        progressColor: colors.white40,
      })
      wavesurfer.load(props.audioUrl)
      wavesurfer.on("seeking", () => {
        if (setCurrentTime) {
          setCurrentTime(wavesurfer.getCurrentTime())
        }
      })
      wavesurfer.on("ready", () => {
        setWaveSurfer(wavesurfer)
        if (typeof props.getWaveSurferInstance === "function") {
          props.getWaveSurferInstance(wavesurfer)
        }
        wavesurfer.zoom(1)

        if (setDuration) {
          setDuration(wavesurfer.getDuration())
        }
      })
      if (setCurrentTime) {
        wavesurfer.on("audioprocess", () => {
          if (setCurrentTime) {
            setCurrentTime(wavesurfer.getCurrentTime())
          }
        })
      }

      return () => {
        wavesurfer.destroy()
        setWaveSurfer(null)
      }
    }
  }, [props.audioUrl, props.zoom, props.waveformHeight, setCurrentTime, setDuration])

  useEffect(() => {
    if (waveSurfer) waveSurfer.setVolume(props.volume)
    if (trackRef.current) trackRef.current.volume = props.volume
  }, [props.volume, waveSurfer, trackRef])

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: props.containerStyles?.flexDirection || "row",
        maxWidth: props.containerStyles?.maxWidth || "50vh",
        marginLeft: "auto",
        marginRight: "auto",
        ...props.containerStyles,
      }}
    >
      <Box
        sx={{
          flexGrow: 7,
          justifyContent: "space-around",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <div ref={waveformRef} id="waveform" />
          <audio src={props.audioUrl} ref={trackRef}>
            <track kind={"captions"} />
          </audio>
        </Box>
      </Box>
    </Container>
  )
}
