import * as React from "react"
import { NavLink, Link } from "react-router-dom"
import IconButton from "@mui/material/IconButton"
import { MenuItem, Typography, Paper, Divider, Box, Button } from "@mui/material"
import Menu from "@mui/material/Menu"
import ListItemText from "@mui/material/ListItemText"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import Avatar from "@mui/material/Avatar"
import NotificationAddOutlinedIcon from "@mui/icons-material/NotificationAddOutlined"
import { useUserInfo } from "../../context/UserInfoProvider"
import { createDownloadUrl, logOut } from "../../util"
import NotificationtList from "./NotificationList"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import { useCart } from "../../context/CartProvider"
import CloseIcon from "@mui/icons-material/Close"
import WMC_LOGO from "../../assets/brands/logo-sq.png"

/**
 * AppBarMenu
 */
export default function AppBarMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [anchorNotificationEl, setAnchorNotificationEl] = React.useState<null | HTMLElement>(null)
  const [anchorShoppingCartEl, setAnchorShoppingCartEl] = React.useState<null | HTMLElement>(null)

  const { userInfo, userNotification, updateUserNotification } = useUserInfo()
  const { cartItems, fetchCartItems, removeFromCart } = useCart() // Use the CartContext

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = () => {
    logOut()
  }

  if (!userInfo) {
    return null
  }

  const { name, profileImage } = userInfo
  const profileImageUrl = createDownloadUrl(profileImage?.downloadUrl)
  const totalAmount = cartItems?.reduce((total, item) => total + item.price, 0) || 0

  return (
    <Box>
      <IconButton
        size="large"
        aria-label="shopping cart"
        aria-controls="menu-shopping-cart"
        aria-haspopup="true"
        onClick={(e) => {
          setAnchorShoppingCartEl(e.currentTarget)
          fetchCartItems()
        }}
        color="inherit"
      >
        <Avatar sx={{ bgcolor: "transparent" }}>
          <ShoppingCartIcon color="secondary" />
        </Avatar>
      </IconButton>

      <Menu
        id="menu-shopping-cart"
        anchorEl={anchorShoppingCartEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorShoppingCartEl)}
        onClose={() => setAnchorShoppingCartEl(null)}
      >
        <Box sx={{ width: "90vw", maxWidth: "300px" }}>
          {cartItems?.length > 0 ? (
            <>
              {cartItems.map((item) => (
                <MenuItem key={item._id} component={Link} to={`/musicDetail/${item._id}`}>
                  <ListItemAvatar>
                    <Box
                      component="img"
                      src={createDownloadUrl(item.project.image?.downloadUrl) || WMC_LOGO}
                      alt={item.name}
                      sx={{ width: 50, height: 50, borderRadius: 1, objectFit: "cover" }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.name}
                    secondary={
                      <>
                        <Typography variant="body2" color="text.secondary">
                          {item.ownedUser}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          ${item.price}
                        </Typography>
                      </>
                    }
                  />
                  <IconButton
                    size="small"
                    edge="end"
                    aria-label="delete"
                    onClick={(e) => {
                      e.preventDefault()
                      removeFromCart(item._id)
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </MenuItem>
              ))}

              {/* Total Amount */}
              <Box sx={{ p: 2, textAlign: "center" }}>
                <Typography variant="h6" color="primary">
                  Total: ${totalAmount.toFixed(2)}
                </Typography>
              </Box>

              {/* Checkout Button */}
              <Box sx={{ p: 2, textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/shoppingCart" // Navigate to /shoppingCart
                >
                  Checkout
                </Button>
              </Box>
            </>
          ) : (
            <Typography variant="body2" color="text.secondary" align="center">
              Your cart is empty
            </Typography>
          )}
        </Box>
      </Menu>

      <IconButton
        size="large"
        aria-label="notifications"
        aria-controls="menu-notification"
        aria-haspopup="true"
        onClick={(e) => {
          if (userNotification?.length > 0) setAnchorNotificationEl(e.currentTarget)
        }}
        color="inherit"
      >
        <Avatar sx={{ bgcolor: "transparent" }}>
          <NotificationAddOutlinedIcon color="secondary" />
        </Avatar>
      </IconButton>
      <Menu
        id="menu-notification"
        anchorEl={anchorNotificationEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorNotificationEl)}
        onClose={() => setAnchorNotificationEl(null)}
      >
        <Box sx={{ width: "90vw", maxWidth: "500px" }}>
          <NotificationtList
            notification={userNotification}
            handleClickNotification={updateUserNotification}
          />
        </Box>
      </Menu>

      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        color="inherit"
      >
        <Avatar alt="User Pic" src={profileImageUrl} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Box sx={{ width: 200 }}>
          <MenuItem component={NavLink} to={`/user/self/portfolio`}>
            <ListItemAvatar>
              <Avatar alt="User Pic" src={profileImageUrl} />
            </ListItemAvatar>
            <ListItemText
              primary={name}
              secondary={
                <Typography variant="body2" color="primary">
                  View Profile
                </Typography>
              }
            />
          </MenuItem>
          <Divider />
          <MenuItem component={Link} to={`/user/self/edit`}>
            Edit Profile
          </MenuItem>

          <MenuItem component={Link} to={`/history/purchased`}>
            Purchasing Record
          </MenuItem>
          <MenuItem component={Link} to={`/history/subscription`}>
            Subscription Plan
          </MenuItem>
          <MenuItem component={Link} to={`/history/saved`}>
            Saved Music
          </MenuItem>

          <MenuItem onClick={handleClose}>Terms of use</MenuItem>
          <Divider />
          <MenuItem onClick={handleLogOut}>Sign Out</MenuItem>
        </Box>
      </Menu>
    </Box>
  )
}
