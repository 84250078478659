import SendIcon from "@mui/icons-material/Send"
import {
  Stack,
  Typography,
  Grid,
  IconButton,
  Button,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  FormControl,
} from "@mui/material"

import { colors } from "../../../theme/colors"
import { MuiAvatar, CommentReply } from "../../../component"
import React, { useState } from "react"
import { createDownloadUrl } from "../../../util"

const commentContainer = {
  // Task stack
  padding: 1,
}

const commentStack = {
  overflowY: "auto",
  height: "calc(100vh - 180px)",
  "> div": {
    // Each Comment
    paddingTop: 1,
    paddingBottom: 1,
    borderBottom: `solid 1px ${colors.white40}`,
    "& div:nth-of-type(2)": {
      // Comment Body
      wordBreak: "break-word",
      paddingLeft: 0,
      paddingRight: 0,
      "& span:nth-of-type(2)": {
        color: colors.yellow,
      },
    },
    "& div:nth-of-type(3)": {
      // Comment Reply
      position: "relative",
      "& button": {
        position: "absolute",
        left: 0,
        bottom: 0,
      },
    },
  },
}
interface CommentProps {
  text: string
  created: string
  replyToText?: string
  replyToUser?: {
    name: string
    profileImage?: {
      downloadUrl: string
    }
    _id: string
  }
  user: {
    name: string
    profileImage?: {
      downloadUrl: string
    }
    _id: string
  }
}

export interface CommentStateProps {
  comments: Array<CommentProps>
  handleCreateComment: Function
}

export default function Comment(props: CommentStateProps) {
  const initState = { text: "", replyToText: "", replyToUser: "" }
  const { comments, handleCreateComment } = props
  const [commentData, setCommentData] = useState(initState)
  const inputRef = React.useRef(null)
  const createComment = () => {
    setCommentData(initState)
    handleCreateComment(commentData)
  }

  const commentList = comments.map((comment: CommentProps, idx: React.Key) => {
    const { text, created, user, replyToUser, replyToText } = comment
    const { name, profileImage, _id } = user
    const profileImageUrl = createDownloadUrl(profileImage?.downloadUrl)

    const userUrl = `/user/${_id}/portfolio`
    const time = new Date(created)
    const commentTime = `${time.toLocaleDateString()} ${time.toLocaleTimeString()}`
    const replyComponent = replyToText ? (
      <CommentReply text={replyToText} user={replyToUser} />
    ) : (
      <></>
    )
    return (
      <MuiAvatar
        key={idx}
        bodyTxt={text}
        subTxtTop={name}
        subTxtBt={commentTime}
        split={[2, 8, 2]}
        imgUrl={profileImageUrl}
        name={name}
        userUrl={userUrl}
        replyComponent={replyComponent}
      >
        <Button
          onClick={() =>
            setCommentData((prev) => ({ ...prev, replyToText: text, replyToUser: _id }))
          }
        >
          Reply
        </Button>
      </MuiAvatar>
    )
  })

  return (
    <Grid container sx={commentContainer}>
      <Grid item xs={12}>
        <Typography variant="h5">Comments</Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column" sx={commentStack}>
          {commentList}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <FormControl sx={{ m: 1, width: "98%" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-comment">{commentData.replyToText}</InputLabel>
          <OutlinedInput
            inputRef={inputRef}
            id="outlined-adornment-comment"
            fullWidth
            multiline
            label={commentData.replyToText}
            value={commentData.text}
            onChange={(e) => setCommentData((prev) => ({ ...prev, text: e.target.value }))}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end" onClick={createComment}>
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}
