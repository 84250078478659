import { ThemeProvider } from "@mui/material/styles"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import CssBaseline from "@mui/material/CssBaseline"
import { theme } from "./theme/theme"
import { DrawerAppBar } from "./component"
import AppRouter from "./router/AppRouter"
import { NotificationProvider, UserInfoProvider, CartProvider } from "./context"
import { loadStripe } from "@stripe/stripe-js"

const stripePromise = loadStripe(
  "pk_test_51PgMAhIzhHMIrh3CHF7fKqNjNafW4IDl6owmYMxjyblHAktZKnfXhcYCkkCRbRNk9PzgQyN97xrz61QyEYjrrCY6009kBe6gNb"
)
export default function App() {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: "test",
  }
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <NotificationProvider>
            <UserInfoProvider>
              <CartProvider>
                <DrawerAppBar>
                  <AppRouter />
                </DrawerAppBar>
              </CartProvider>
            </UserInfoProvider>
          </NotificationProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  )
}
