import React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { Button, Divider, Grid, Link, useMediaQuery } from "@mui/material"
import All from "./tab/All"
import { Page, PageContainer, SwipeableListGroup } from "../../component"
import {
  MusicCreationBanner,
  WebThreeBanner,
  CreateAndEarnBanner,
  LaunchPartyBanner,
  JamSessionComingSoonBanner,
} from "../../assets/carousel"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import Premium from "./tab/Premium"
import Standard from "./tab/Standard"
import SoundEffect from "./tab/SoundEffect"

type MarketPlaceTab = "all" | "premium" | "standard" | "soundEffect"

const CAROUSEL_HEIGHT = "auto"

const CAROUSEL_ITEMS = [
  {
    label: "music creation no limit",
    imageSrc: MusicCreationBanner,
  },
  {
    label: "web 3 music ecosystem",
    imageSrc: WebThreeBanner,
  },
  {
    label: "create and earn now",
    imageSrc: CreateAndEarnBanner,
  },
  {
    label: "launch party november 2024",
    imageSrc: LaunchPartyBanner,
  },
  {
    label: "jam session coming soon",
    imageSrc: JamSessionComingSoonBanner,
  },
]

const MARKETPLACE_TABS = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "premium",
    label: "Premium",
  },
  {
    value: "standard",
    label: "Standard",
  },
  {
    value: "soundEffect",
    label: "Sound effect",
  },
] as const

/**
 * Market place page
 */
export default function MarketPlacePage() {
  const isMobile = useMediaQuery("(max-width:600px)")

  const [activeTab, setActiveTab] = React.useState<MarketPlaceTab>("all")

  return (
    <div>
      <Page pageId={MarketPlacePage} noPadding>
        <PageContainer>
          <Grid item xs={12} alignItems={"center"}>
            <SwipeableListGroup
              containerHeight={CAROUSEL_HEIGHT}
              slideWidth={isMobile ? "100%" : "75%"}
              slideHeight={"auto"}
              gap={isMobile ? 0 : -261}
              items={CAROUSEL_ITEMS}
            />
          </Grid>
          <Tabs
            value={activeTab}
            onChange={(event: React.SyntheticEvent, tab: MarketPlaceTab) => setActiveTab(tab)}
            textColor="primary"
            indicatorColor="primary"
          >
            {MARKETPLACE_TABS.map(({ value, label }) => (
              <Tab key={value} value={value} label={label} sx={{ textTransform: "none" }} />
            ))}
          </Tabs>

          <Divider sx={{ mb: 2 }} />

          {activeTab === "all" && <All />}
          {activeTab === "premium" && <Premium />}
          {activeTab === "standard" && <Standard />}
          {activeTab === "soundEffect" && <SoundEffect />}
        </PageContainer>
      </Page>

      <Button
        onClick={() => {}}
        component={Link}
        href="/uploadSong"
        type="submit"
        color={"primary"}
        variant="contained"
        sx={{
          position: "fixed",
          right: "0%",
          bottom: "0%",
          borderRadius: 30,
          py: 1.5,
          mr: 3,
          mb: 10,
          zIndex: 999,
        }}
        endIcon={<ArrowForwardIcon />}
      >
        Upload
      </Button>
    </div>
  )
}
