import React from "react"
import { Grid, Typography } from "@mui/material"
import { InstrumentList, MusicStyleList } from ".."

interface ProjectInstrumentsProps {
  handleCheckBoxToggleChange?: (_event: React.ChangeEvent<HTMLInputElement>, _key: string) => void
  handleAddInstrument?: (_selected: string) => void
  handleRemoveInstrument?: (_selected: string) => void
  selectedStyles: string[]
  selectedInstruments?: string[]
}

/**
 * Instrumentation and Style for Project form
 */
export default function ProjectInstruments({
  handleAddInstrument = () => {},
  handleRemoveInstrument = () => {},
  handleCheckBoxToggleChange = () => {},
  selectedStyles,
  selectedInstruments,
}: ProjectInstrumentsProps) {
  return (
    <>
      <Typography gutterBottom variant="formTitle">
        Instrumentation & Style
      </Typography>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <Typography gutterBottom variant="body1">
            Instruments
          </Typography>
          <InstrumentList
            selectedInstruments={selectedInstruments}
            handleAddInstrument={handleAddInstrument}
            handleRemoveInstrument={handleRemoveInstrument}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography gutterBottom variant="body1">
            Music Styles
          </Typography>
          <MusicStyleList
            styleOfInterest={selectedStyles}
            stateKey="instruments"
            handleCheckBoxToggleChange={handleCheckBoxToggleChange}
            displayTitle={false}
            cols={{
              xs: 12,
              md: 6,
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
