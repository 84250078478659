import React, { useState, useEffect } from "react"
import { Box, Button, Stack, Typography, Grid, Paper } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { createDownloadUrl } from "../../util"
import WMC_LOGO from "../../assets/brands/logo-sq.png"
import AcknowledgementItem from "../../component/AcknowledgementItem/AcknowledgementItem"
import CancelIcon from "@mui/icons-material/Cancel"
import { useNavigate, useParams } from "react-router-dom" // To get the orderId from the URL
import OrderAPI from "../../api/order" // Import the Order API to fetch order details
import { colors } from "../../theme/colors"
/**
 * Acknowledgement Page
 */
export default function AcknowledgementPage() {
  const { orderId } = useParams() // Get orderId from the URL parameters
  const [order, setOrder] = useState<any[]>([]) // To store order details
  const [loading, setLoading] = useState(true) // To handle loading state
  const [error, setError] = useState("") // To handle errors
  const [status, setStatus] = useState("pending")
  const navigate = useNavigate()

  // Fetch order details by orderId when component mounts
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const { res } = await OrderAPI.getOrderById(orderId) // Call the Order API
        setOrder(res.order.items) // Set order details from the response
        setStatus(res.order.status)
        setLoading(false) // Set loading to false when the order is fetched
      } catch (err) {
        console.error("Error fetching order:", err)
        setError("Failed to load order details.")
        setLoading(false) // Set loading to false even on error
      }
    }

    if (orderId) {
      fetchOrder() // Call fetchOrder if orderId exists
    }
  }, [orderId])

  if (loading) {
    return (
      <Box
        sx={{
          color: "#fff",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 4,
        }}
      >
        <Typography variant="h5">Loading order details...</Typography>
      </Box>
    )
  }

  if (error) {
    return (
      <Box
        sx={{
          color: "#fff",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 4,
        }}
      >
        <Typography variant="h5" color="error">
          {error}
        </Typography>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        color: "#fff",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 4,
      }}
    >
      <Stack alignItems="center" spacing={2}>
        {status === "completed" ? (
          <>
            <CheckCircleIcon sx={{ fontSize: 60, color: "#00e676" }} />
            <Typography variant="h4" align="center">
              Thank you!
            </Typography>
            {order?.length === 0 ? (
              <Typography variant="body1" align="center">
                Monthly plan has been subscribed successfully
              </Typography>
            ) : (
              <Typography variant="body1" align="center">
                {order.length} items have been purchased successfully
              </Typography>
            )}
          </>
        ) : (
          <>
            <CancelIcon sx={{ fontSize: 60, color: "#EB5757" }} />
            <Typography variant="h4" align="center">
              Payment is not completed
            </Typography>
            <Typography variant="body1" align="center">
              Due to some unknown reasons, the payment is not successful
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#ff7d00",
                color: "#fff",
                marginTop: 2,
              }}
              onClick={() => navigate("/shoppingCart")}
            >
              Try Again
            </Button>
          </>
        )}
      </Stack>

      {order?.length > 0 && status === "completed" && (
        <>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={7}>
              <Paper sx={{ padding: 2 }}>
                {order.map((item, index) => (
                  <AcknowledgementItem
                    key={index}
                    title={item.name}
                    artist={
                      item.musicId?.project?.users?.find((t: any) => t.role === "LEAD")?.user
                        ?.name ?? ""
                    }
                    price={item.price}
                    format={item.musicId?.fileType}
                    imageUrl={
                      createDownloadUrl(item.musicId?.project?.image?.downloadUrl) || WMC_LOGO
                    }
                  />
                ))}
              </Paper>
            </Grid>
          </Grid>

          {/* View Subscription Record or Purchase Record Button */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#ff7d00",
              color: "#fff",
              textTransform: "none",
              marginTop: 4,
              paddingX: 4,
            }}
            onClick={() => navigate("/history/purchased")}
          >
            {"View Purchasing Record"}
          </Button>
        </>
      )}
    </Box>
  )
}
