import { Box, Button, Grid } from "@mui/material"
import ProjectItem from "component/ProjectItem/ProjectItem"
import { useState } from "react"
import { colors } from "../../theme/colors"
import { ProjectProps } from "../../types"

enum Tab {
  Public = "Public",
  Private = "Private",
  Finished = "Finished",
}

type ProfileProjectsProps = {
  projects: { project: ProjectProps }[]
  isSelf: boolean
}
/**
 * Profile Projects
 */
export default function ProfileProjects({ projects, isSelf }: ProfileProjectsProps) {
  const [tab, setTab] = useState<Tab>(Tab.Public)

  const finishedProjects = projects.filter((t) => t.project.state === "FINISHED")
  const publicProjects = projects.filter((t) => t.project.status === "PUBLIC")
  const privateProjects = projects.filter((t) => t.project.status === "PRIVATE")

  return (
    <Box>
      <Box margin="5px">
        {isSelf && (
          <Button
            sx={{
              textTransform: "none",
              backgroundColor: tab === Tab.Private ? colors.orange30 : "",
              color: tab === Tab.Private ? "#fff" : "",
            }}
            variant="outlined"
            onClick={() => setTab(Tab.Private)}
          >
            Private
          </Button>
        )}
        <Button
          sx={{
            textTransform: "none",
            ml: 2,
            backgroundColor: tab === Tab.Public ? colors.orange30 : "",
            color: tab === Tab.Public ? "#fff" : "",
          }}
          variant={tab === Tab.Public ? "contained" : "outlined"}
          onClick={() => setTab(Tab.Public)}
        >
          Public
        </Button>
        <Button
          sx={{
            textTransform: "none",
            ml: 2,
            backgroundColor: tab === Tab.Finished ? colors.orange30 : "",
            color: tab === Tab.Finished ? "#fff" : "",
          }}
          variant={tab === Tab.Finished ? "contained" : "outlined"}
          onClick={() => setTab(Tab.Finished)}
        >
          Finish
        </Button>
      </Box>
      {
        <Grid container justifyContent={"flex-start"} columnSpacing={2} padding={2}>
          {tab === Tab.Private &&
            privateProjects.map((project) => {
              return (
                <ProjectItem
                  key={project.project._id + tab}
                  project={project.project as ProjectProps}
                />
              )
            })}
          {tab === Tab.Public &&
            publicProjects.map((project) => {
              return (
                <ProjectItem
                  key={project.project._id + tab}
                  project={project.project as ProjectProps}
                />
              )
            })}
          {tab === Tab.Finished &&
            finishedProjects.map((project) => {
              return (
                <ProjectItem
                  key={project.project._id + tab}
                  project={project.project as ProjectProps}
                />
              )
            })}
        </Grid>
      }
    </Box>
  )
}
