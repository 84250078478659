/* eslint-disable import/no-unresolved */
import { Box } from "@mui/material"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode, Navigation } from "swiper/modules"
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/navigation"

interface SwipeableListGroupItem {
  label: string
  imageSrc: string
}

interface SwipeableListGroupProps {
  containerWidth?: number | string
  containerHeight?: number | string
  slideWidth?: number | string
  slideHeight?: number | string
  gap?: number
  items: SwipeableListGroupItem[]
}

const DEFAULT_CONTAINER_WIDTH = "100%"
const DEFAULT_CONTAINER_HEIGHT = 384
const DEFAULT_SLIDE_WIDTH = 280
const DEFAULT_SLIDE_HEIGHT = 384
const DEFAULT_GAP = 24

/**
 * Swipeable carousel for image display
 */
export default function SwipeableListGroup({
  containerWidth = DEFAULT_CONTAINER_WIDTH,
  containerHeight = DEFAULT_CONTAINER_HEIGHT,
  slideWidth = DEFAULT_SLIDE_WIDTH,
  slideHeight = DEFAULT_SLIDE_HEIGHT,
  gap = DEFAULT_GAP,
  items,
}: SwipeableListGroupProps) {
  return (
    <Box
      width={containerWidth}
      height={containerHeight}
      sx={{
        display: "grid",
        overflow: "hidden",
        "--swiper-navigation-size": "28px",
        ".swiper-button-next": {
          color: "#ffffff66",
          // pointerEvents: "none"
        },
        ".swiper-button-prev": {
          color: "#ffffff66",
          // pointerEvents: "none"
        },
      }}
    >
      <Swiper
        style={{ width: "100%", justifyContent: "space-between" }}
        slidesPerView={"auto"}
        spaceBetween={gap}
        freeMode={true}
        navigation={true}
        modules={[FreeMode, Navigation]}
      >
        {items?.map((item, index) => (
          <SwiperSlide key={"carousel_" + index} style={{ width: slideWidth, height: slideHeight }}>
            <Box
              component="img"
              src={item?.imageSrc}
              alt={item?.label}
              sx={{
                width: slideWidth,
                height: slideHeight,
                objectFit: "contain",
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}
