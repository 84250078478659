//@ts-ignore
import { musicKeyList } from "@womucon/share"
import { UploadSongFormStateProps } from "../../types/uploadSongTypes"

export const musicKeys = musicKeyList

export const tempoMarks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 260,
    label: "260",
  },
]

export const UploadSongFormInitiateState: UploadSongFormStateProps = {
  name: "",
  description: "",
  instruments: [],
  styles: [],
  tempo: 80,
  musicKey: "Cb Major",
  metreUpper: 1,
  metreLower: 1,
  //
  musicType: "",
  currency: "",
  price: "",
}

export const MUSIC_TYPES = ["PREMIUM", "STANDARD"]
