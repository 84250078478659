import React, { useEffect, useState } from "react"
import { Box, Grid } from "@mui/material"
import { MusicAPI, OrderAPI, SavedMusicAPI } from "../../api"
import MarketPlaceTable from "../../component/MarketPlace/Table/MarketPlaceTable"
import OrderMusicTable from "./OrderMusicTable"
import { OrderItem } from "./historyTypes"
import { createDownloadUrl } from "../../util"

/**
 * Purchasing Record Tab
 */
export default function PurchasingRecord() {
  const [orders, setOrders] = React.useState<OrderItem[]>([])
  const [, setError] = useState("")

  useEffect(() => {
    const getAPIOrders = async () => {
      const response = await OrderAPI.getOrders()
      if (response?.err) {
        setError(response?.err)
        return
      }
      setOrders(response?.res.order)
    }
    getAPIOrders()
  }, [])

  console.log(orders)

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container columnSpacing={2}>
        <Grid item xs={10}>
          <OrderMusicTable
            data={orders
              ?.filter((order) => order.status === "completed")
              .flatMap((order) =>
                order.items.map((item) => ({
                  ...item.musicId,
                  createdAt: order.createdAt,
                  image: item.musicId.project.image,
                  musicUrl: createDownloadUrl(item.musicId.downloadUrl),
                }))
              )
              .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
