import { Box, Stack, Typography } from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import { formatDistance } from "date-fns"
import WMC_LOGO from "../../../assets/brands/logo-sq.png"
import { MarketPlaceItem } from "../../../types/marketPlace"
import { createDownloadUrl } from "../../../util"
import { Buttons } from "./Buttons"

import { useEffect, useState } from "react"
import { CartAPI, SavedMusicAPI } from "../../../api"
import ShareToPopUp from "../../../component/ShareToPopUp/ShareToPopUp"
import { useUserInfo } from "../../../context/UserInfoProvider"
import { useNavigate } from "react-router-dom"

const ICON_SIZE = 64

/**
 * Table in market place
 */
export default function MarketPlaceTable({ data }: { data: MarketPlaceItem[] }) {
  const [savedMusics, setSavedMusics] = useState<string[]>([])
  const { userToken } = useUserInfo()
  useEffect(() => {
    const fetchSavedMusics = async () => {
      try {
        const response = await SavedMusicAPI.getSavedMusic()
        if (response?.err) {
          console.error("Failed to get saved musics")
          return
        }
        console.log("Saved musics:", response)
        setSavedMusics(response.res.map((t: { musicId: { _id: string } }) => t.musicId._id))
      } catch (err) {
        console.error("Failed to get saved musics:", err)
      }
    }
    if (userToken) fetchSavedMusics()
  }, [userToken])
  const handleAddToCart = async (musicId: string) => {
    try {
      const response = await CartAPI.addToCart(musicId)
      if (response?.err) {
        console.error("Failed to add item to cart")
        return
      }
      console.log("Item added to cart:", response)
      // Handle success (e.g., show a notification or update the UI)
    } catch (err) {
      console.error("Failed to add item to cart:", err)
    }
  }
  const [openShareDialog, setOpenShareDialog] = useState(false)
  const [sharingMarketPlaceItem, setSharingMarketPlaceItem] = useState<MarketPlaceItem | null>(null)

  const handleShareClick = (item: MarketPlaceItem) => {
    setSharingMarketPlaceItem(item) // Set the selected item to share
    setOpenShareDialog(true) // Open the share dialog
  }

  const handleShareDialogClose = () => {
    setOpenShareDialog(false)
    setSharingMarketPlaceItem(null) // Clear the selected item after sharing
  }

  const handleSaveMusic = async (musicId: string) => {
    console.log("Save music clicked", musicId)
    const response = await SavedMusicAPI.saveMusic(musicId)
    if (response?.err) {
      console.error("Failed to save music")
      return
    }
    console.log("Music saved:", response)
    setSavedMusics([...savedMusics, musicId])
  }

  const handleUnsaveMusic = async (musicId: string) => {
    console.log("Unsave music clicked", musicId)
    const response = await SavedMusicAPI.unsaveMusic(musicId)
    if (response?.err) {
      console.error("Failed to unsave music")
      return
    }
    console.log("Music unsaved:", response)

    const index = savedMusics.indexOf(musicId)
    if (index > -1) {
      setSavedMusics(savedMusics.filter((_, i) => i !== index))
    }
  }
  const navigate = useNavigate()
  return (
    <Box sx={{ width: "100%", display: "table", tableLayout: "fixed", whiteSpace: "nowrap" }}>
      <TableContainer component={Paper}>
        <Table sx={{}} aria-label="simple table">
          <TableBody>
            {data?.map((row) => {
              const projectImageUrl = createDownloadUrl(row?.image?.downloadUrl)
              const dateDistance = formatDistance(new Date(row?.uploadTimestamp), new Date())
              return (
                <TableRow
                  key={row?._id}
                  hover
                  sx={{
                    height: 100,
                    "&:nth-of-type(n)": { backgroundColor: "background.default" },
                    "&:last-child td, &:last-child th": { border: "none" },
                  }}
                >
                  <TableCell align="left" scope="row">
                    <Stack direction="row" spacing={4} alignItems={"center"}>
                      <img
                        src={projectImageUrl}
                        alt="thumbnail"
                        width={ICON_SIZE}
                        height={ICON_SIZE}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null // prevents looping
                          currentTarget.src = WMC_LOGO
                        }}
                      />

                      <Box onClick={() => navigate(`/musicDetail/${row._id}`)}>
                        <Typography variant="body1" fontWeight={"bold"}>{`${
                          row?.projectName || "--"
                        }`}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {row?.name ?? "--"}
                        </Typography>
                      </Box>
                    </Stack>
                  </TableCell>

                  <TableCell align="center">
                    {row?.musicType === "STANDARD" ? (
                      <Typography variant="body2">{"STANDARD"}</Typography>
                    ) : (
                      <Stack direction="row" spacing={1}>
                        <Typography variant="body2">{row?.currency ?? "--"}</Typography>
                        <Typography variant="body2">{row?.price ?? "--"}</Typography>
                      </Stack>
                    )}
                  </TableCell>

                  <TableCell align="left">
                    <Typography variant="body2">
                      {row?.styles?.map((style: string) => style?.capitalize()).join(", ")}
                    </Typography>
                  </TableCell>

                  <TableCell align="center">
                    <span>{row?.download?.toLocaleString() ?? "--"} downloads</span>
                  </TableCell>

                  <TableCell align="center">{<span>{dateDistance ?? "--"}</span>} ago</TableCell>

                  <TableCell align="right" scope="row">
                    {row?.purchased ? (
                      <Buttons.Download onClick={() => console.log("Download button clicked")} />
                    ) : (
                      <Buttons.BuyNowOrAddToCart
                        onBuyNowClick={() => console.log("Buy now button clicked")}
                        onAddToCartClick={() => handleAddToCart(row?._id)}
                      />
                    )}
                    <Buttons.Share sx={{ mx: 0.5 }} onClick={() => handleShareClick(row)} />
                    <Buttons.Bookmark
                      onClick={() => {
                        savedMusics.includes(row._id)
                          ? handleUnsaveMusic(row._id)
                          : handleSaveMusic(row._id)
                      }}
                      saved={savedMusics.includes(row._id)}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {sharingMarketPlaceItem && (
        <ShareToPopUp
          open={openShareDialog}
          onClose={handleShareDialogClose}
          musicId={sharingMarketPlaceItem._id}
          musicName={sharingMarketPlaceItem.name}
        />
      )}
    </Box>
  )
}
