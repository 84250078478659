import React from "react"
import { Box, Grid, Typography, Button } from "@mui/material"
import CancelIcon from "@mui/icons-material/Cancel"
import { colors } from "../../theme/colors"
import useMUIMediaQuery from "../../hooks/useMUIMedia"

type ShoppingCartMusicItemProps = {
  title: string
  artist: string
  price: number
  format: string
  imageUrl?: string
  onRemove: () => void
}

/**
 * ShoppingCartMusicItem component
 */
export default function ShoppingCartMusicItem({
  title,
  artist,
  price,
  format,
  imageUrl,
  onRemove,
}: ShoppingCartMusicItemProps) {
  const { isMobile } = useMUIMediaQuery()

  return (
    <Box
      sx={{
        padding: 2,
        marginBottom: 2,
        border: `1px solid ${colors.grey}`,
        borderRadius: "8px",
      }}
    >
      <Grid container spacing={2} alignItems="center" direction={"row"}>
        {/* Image and Title */}
        <Grid item xs={6} sm={6} md={6}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={imageUrl}
              alt={`${title} cover`}
              style={{
                width: isMobile ? 40 : 50,
                height: isMobile ? 40 : 50,
                marginRight: isMobile ? 12 : 16,
              }}
            />
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#fff",
                  fontSize: isMobile ? "1rem" : "1.1rem",
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: colors.grey,
                  fontSize: isMobile ? "0.9rem" : "1rem",
                }}
              >
                {artist}
              </Typography>
            </Box>
          </Box>
          {isMobile && (
            <Typography
              variant="body2"
              sx={{
                color: colors.grey,
                fontSize: isMobile ? "0.85rem" : "1rem",
                marginTop: "5px",
              }}
            >
              Format: {format}
            </Typography>
          )}
        </Grid>

        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: isMobile ? "flex-start" : "center",
            justifyContent: isMobile ? "space-between" : "flex-end",
            gap: isMobile ? 1 : 2,
          }}
        >
          {!isMobile && (
            <Typography
              variant="body2"
              sx={{
                color: colors.grey,
                fontSize: isMobile ? "0.85rem" : "1rem",
              }}
            >
              Format: {format}
            </Typography>
          )}
          {isMobile && (
            <CancelIcon
              sx={{
                color: colors.grey,
                cursor: "pointer",
                marginLeft: "auto",
              }}
              onClick={onRemove}
            />
          )}
          <Typography
            variant="body1"
            sx={{
              color: "#fff",
              p: "5px",
              bgcolor: colors.darkGrey2,
              borderRadius: "4px",
              textAlign: "center",
              fontSize: isMobile ? "0.9rem" : "0.9rem",
              marginBottom: isMobile ? "8px" : 0,
              marginLeft: isMobile ? "auto" : 0,
            }}
          >
            {price !== 0 ? `HKD ${price}` : "Standard"}
          </Typography>
          {!isMobile && (
            <Button
              variant="outlined"
              sx={{
                borderColor: colors.grey,
                color: colors.grey,
                textTransform: "none",
                width: isMobile ? "10px" : "auto",
                fontSize: isMobile ? "0.85rem" : "0.9rem",
                padding: isMobile ? "1px" : "8px 16px",
              }}
              startIcon={<CancelIcon />}
              onClick={onRemove}
            >
              {!isMobile && "Remove"}
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
