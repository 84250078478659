import React, { useEffect } from "react"
import { useUserInfo } from "../../context/UserInfoProvider"
import { Link } from "react-router-dom"
import { Button } from "@mui/material"
import ProfileHeader from "../../component/ProfileHeader/ProfileHeader"
import ProfileProjects from "../../component/ProfileProjects/ProfileProjects"
import { createDownloadUrl } from "../../util"
/**
 * This is the user portfolio Page, this page should be viewable to anyone
 * The editing of portfolio page should be restricted to the user itself and possibly the admin
 */
export default function SelfPortfolioPage2() {
  const { userInfo, updateUserInfo } = useUserInfo()

  useEffect(() => {
    updateUserInfo()
  }, [])

  if (!userInfo) return <></>

  const coverImageUrl = createDownloadUrl(userInfo.coverImage?.downloadUrl)

  const editBtn = (
    <Button to={`/user/self/edit`} component={Link} variant="text" color="primary">
      Edit
    </Button>
  )

  return (
    <>
      <ProfileHeader isSelf={true} {...userInfo} editBtn={editBtn} coverImageUrl={coverImageUrl} />

      <ProfileProjects isSelf={true} {...userInfo} />
    </>
  )
}
