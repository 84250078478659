import { Typography, CardActionArea, Stack, Avatar } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import { renderIcon } from "../../../component/MusicStyleList/musicStyleData"

const ICON_SIZE = 48

/**
 * Filter menu item in market place
 */
export default function FilterMenuItem({
  focused,
  image,
  label,
  onClick,
}: {
  focused: boolean
  image?: string
  label?: string
  onClick?: () => void
}) {
  return (
    <CardActionArea sx={{ pr: 1, mb: 2 }} onClick={onClick}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={2}>
          {image ? (
            <img src={renderIcon(image)} alt="thumbnail" width={ICON_SIZE} height={ICON_SIZE} />
          ) : (
            <Avatar
              variant="square"
              alt={image}
              sx={{
                width: ICON_SIZE,
                height: ICON_SIZE,
              }}
            />
          )}
          <Typography variant="body1">{label}</Typography>
        </Stack>
        {focused && <CheckIcon color={"primary"} />}
      </Stack>
    </CardActionArea>
  )
}
