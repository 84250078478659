/* eslint-disable no-undef */

const env = "staging"
const site = process.env.SITE ? `${process.env.SITE}-` : "app"
const siteApi = process.env.SITE ? `${process.env.SITE}-` : ""

const apiUrl = {
  production: `https://${siteApi}api.womucon.com`,
  staging: `https://${siteApi}api-staging.womucon.com`,
  development: "http://localhost:3001",
}

const appUrl = {
  production: `https://${site}.womucon.com`,
  staging: `https://${site}-staging.womucon.com`,
  development: "http://localhost:3000",
}

export const config = {
  appUrl: appUrl[env],
  apiUrl: `${apiUrl[env]}/api/v1`,
  fileServerUrl: apiUrl[env],
  localAssetUrl: appUrl[env],
  facebookAppId: process.env.FACEBOOK_APP_ID || "873913620483818",
  googleClientId:
    process.env.GOOGLE_CLIENT_ID ||
    "791565960255-m1clbcfabqqr1t026kkh23vuf0mgaj5b.apps.googleusercontent.com",
}
