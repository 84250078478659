import * as React from "react"
import moment from "moment"
import {
  SelectChangeEvent,
  Typography,
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
} from "@mui/material"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { InstrumentExperienceProps, UserFormStateProps } from "../../types"
import { ErrorRecord, FormErrorDisplay } from "../../hooks/useFormValidation"
import AgreementCheckBox from "../AgreementCheckBox"
import without from "lodash/without"
import BasicInfo from "./BasicInfo"
import MusicProfile from "./MusicProfile/MusicProfile"
import UserInstrument from "./UserInstrument"
import { createDownloadUrl } from "../../util"

interface UserFormProps {
  onSubmit: () => void
  formState: UserFormStateProps
  setFormState: (_: Partial<UserFormStateProps>) => void
  state?: "editing" | "creating"
  isLoading?: boolean
  children?: React.ReactNode
  hasErrorInForm: (_: Array<keyof UserFormStateProps>) => boolean
  errors: ErrorRecord<UserFormStateProps>
  isFormValid: () => boolean
  formErrorDisplay: FormErrorDisplay<UserFormStateProps>
  updateFormErrorDisplay: (_: Partial<Partial<Record<keyof UserFormStateProps, boolean>>>) => void
}

/**
 * User Form Component
 */
export default function UserForm({
  formState,
  setFormState,
  onSubmit,
  updateFormErrorDisplay,
  isFormValid,
  errors,
  formErrorDisplay,
}: UserFormProps) {
  const {
    profileImage,
    name,
    language,
    description,
    dateOfBirth,
    musicEducation,
    styleOfInterest,
    musicCertificate,
    recordingLevel,
    email,
    recordingSoftware,
    instruments,
    password,
    agreement,
  } = formState

  const fullProfileImageDownloadUrl = createDownloadUrl(profileImage?.downloadUrl)
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value
    const key = event.target.name as keyof UserFormStateProps

    setFormState({
      [key]: key === "language" ? [value] : value,
    })
  }

  const onShowErrors = React.useCallback(() => {
    updateFormErrorDisplay(
      Object.keys(errors).reduce((prev, current) => {
        return { ...prev, [current]: true }
      }, {})
    )
  }, [updateFormErrorDisplay, errors])

  const handleChangeEducation = (_: React.MouseEvent<HTMLElement, MouseEvent>, value: number) => {
    setFormState({ recordingLevel: value })
  }

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    // TODO: change to regex
    const val = name === "name" ? value.trim() : value
    setFormState({ [name]: val })
  }

  const handleDateChange = (date: string | null) => {
    if (date) {
      const dobYear = moment(date).format("YYYY")
      const dobMonth = moment(date).format("MM")
      setFormState({
        dateOfBirth: new Date(`${dobMonth}/1/${dobYear}`),
        dobYear,
        dobMonth,
      })
    }
  }

  const handleAgreement = () => setFormState({ agreement: { agree: !agreement.agree } })

  const handleUpdateInstrument = (newInstrumentState: InstrumentExperienceProps[]) => {
    setFormState({ instruments: newInstrumentState })
  }

  const handleUpdateProfileImage = (file: File) => {
    if (file) {
      setFormState({ profileImageFile: file })
    }
  }

  const handleMusicCheckBoxToggleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const { value, checked } = event.target
    let newState: string[]
    if (checked) {
      newState = [
        // @ts-ignore
        ...formState[key as keyof UserFormStateProps],
        value.toLowerCase(),
      ]
    } else {
      newState = without(
        // @ts-ignore
        formState[key as keyof UserFormStateProps],
        value.toLowerCase()
      )
    }
    setFormState({ [key]: newState })
  }

  const getErrorMessage = (key: string): string | undefined => {
    return errors[key as keyof UserFormStateProps]
  }

  const shouldDisplayError = (key: string) => {
    return formErrorDisplay[key as keyof UserFormStateProps] as boolean
  }

  const editFormErrorDisplay = (key: string) => {
    updateFormErrorDisplay({
      [key]: true,
    })
  }

  if (!Object.keys(formState).length) {
    return null
  }

  return (
    <Stack spacing={4}>
      <div>
        <Typography gutterBottom variant="formTitle">
          Basic Info
        </Typography>
        <BasicInfo
          shouldDisplayError={shouldDisplayError}
          editFormErrorDisplay={editFormErrorDisplay}
          getErrorMessage={getErrorMessage}
          handleSelectChange={handleSelectChange}
          handleTextChange={handleTextChange}
          handleUpdateProfileImage={handleUpdateProfileImage}
          name={name}
          password={password}
          email={email}
          language={language}
          description={description}
          dateOfBirth={dateOfBirth}
          profileImageUrl={fullProfileImageDownloadUrl}
          handleDateChange={handleDateChange}
        />
      </div>

      <div>
        <Typography gutterBottom variant="formTitle">
          Optional Music Profile and Level
        </Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>Detail</AccordionSummary>
          <AccordionDetails>
            <MusicProfile
              shouldDisplayError={shouldDisplayError}
              editFormErrorDisplay={editFormErrorDisplay}
              getErrorMessage={getErrorMessage}
              handleChangeEducation={handleChangeEducation}
              handleTextChange={handleTextChange}
              musicEducation={musicEducation}
              recordingLevel={recordingLevel}
              musicCertificate={musicCertificate}
              styleOfInterest={styleOfInterest}
              recordingSoftware={recordingSoftware}
              handleSelectChange={handleSelectChange}
              handleCheckBoxToggleChange={handleMusicCheckBoxToggleChange}
            />
            <UserInstrument
              instruments={instruments}
              handleUpdateInstrument={handleUpdateInstrument}
            />
          </AccordionDetails>
        </Accordion>
      </div>

      <AgreementCheckBox
        checked={agreement?.agree}
        onChange={handleAgreement}
        error={!!getErrorMessage("agreement")}
      />

      <Box sx={{ textAlign: "right" }}>
        <Button onClick={() => history.back()} variant="text" color="primary" sx={{ mx: 2 }}>
          Cancel
        </Button>
        <Button
          onClick={isFormValid() ? onSubmit : onShowErrors}
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          data-testid="user-form-submit-btn"
        >
          Submit
        </Button>
      </Box>
    </Stack>
  )
}
