/* eslint-disable jsx-a11y/no-autofocus */
import React from "react"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import Typography from "@mui/material/Typography"
import { useNavigate } from "react-router-dom"
import { saveToken } from "../../util/auth-utils"
import { UserAuthProps } from "../../types"
import { Copyright, SocialMediaLogin } from "../../component"
import { UserAPI } from "../../api"
import { useNotification } from "../../context/NotificationProvider"

const images = [
  "https://www.womucon.com/wp-content/uploads/2023/03/shutterstock_2136967351.jpg",
  "https://www.womucon.com/wp-content/uploads/2023/03/shutterstock_1958073748.jpg",
  "https://www.womucon.com/wp-content/uploads/2023/03/shutterstock_1907253631.jpg",
  "https://www.womucon.com/wp-content/uploads/2023/03/shutterstock_1894023946.jpg",
  "https://www.womucon.com/wp-content/uploads/2023/03/shutterstock_1892456077.jpg",
  "https://www.womucon.com/wp-content/uploads/2023/03/shutterstock_1783167689.jpg",
  "https://www.womucon.com/wp-content/uploads/2023/03/shutterstock_1760613929.jpg",
  "https://www.womucon.com/wp-content/uploads/2023/03/shutterstock_1760613920.jpg",
  "https://www.womucon.com/wp-content/uploads/2023/03/shutterstock_1759840001.jpg",
  "https://www.womucon.com/wp-content/uploads/2023/03/shutterstock_1703007196.jpg",
  "https://www.womucon.com/wp-content/uploads/2023/03/shutterstock_1678390384.jpg",
  "https://www.womucon.com/wp-content/uploads/2023/03/shutterstock_1670145439.jpg",
  "https://www.womucon.com/wp-content/uploads/2023/03/shutterstock_1564166668.jpg",
  "https://www.womucon.com/wp-content/uploads/2023/03/shutterstock_1511619098.jpg",
  "https://www.womucon.com/wp-content/uploads/2023/03/shutterstock_1261289038.jpg",
  "https://www.womucon.com/wp-content/uploads/2023/03/shutterstock_648996148.jpg",
  "https://www.womucon.com/wp-content/uploads/2023/03/shutterstock_562991365.jpg",
  "https://www.womucon.com/wp-content/uploads/2023/03/shutterstock_525786160.jpg",
  "https://www.womucon.com/wp-content/uploads/2023/03/shutterstock_420915238.jpg",
]

const random = Math.floor(Math.random() * images.length)

/**
 * Auth Component
 */
export default function Auth() {
  const navigate = useNavigate()
  const { openNotification } = useNotification()
  const [form, setForm] = React.useState<UserAuthProps>({
    email: "",
    password: "",
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const handleLogin = async (provider: string, rawData: any) => {
    const data: any = {}

    if (provider === "google") {
      data.token = rawData.credential
    }

    if (provider === "facebook") {
      data.token = rawData.accessToken
      data.email = rawData.email
      data.userId = rawData.userID
    }

    if (provider === "basic") {
      data.email = form.email
      data.password = form.password
    }

    const { res, error } = await UserAPI.login(provider, data)

    if (res?.token) {
      saveToken(res.token)

      // Purposefully use location.assign to force the render of the projects page
      location.assign("/marketPlace")
      return
    }
    if (res?.errorMessage) {
      if (res?.errorMessage === "USER NOT EXIST") {
        let url = res?.email
          ? `/signup?email=${encodeURIComponent(res.email)}`
          : rawData.email
          ? `/signup?email=${encodeURIComponent(rawData.email)}`
          : "/signup"

        navigate(url)
        return
      }
    }
    if (error.message === "USER NOT EXIST") {
      let url = rawData.email ? `/signup?email=${encodeURIComponent(rawData.email)}` : "/signup"

      navigate(url)
      return
    }

    if (error.message === "USER NOT VERIFY") {
      handleReject(error)
    }
  }

  const handleReject = (err: any) => {
    openNotification("ERROR", err.message)
  }

  const handleSignup = () => {
    let url = "/signup"

    if (form.email !== "") {
      url += `?email=${encodeURIComponent(form.email)}`
    }

    location.assign(url)
  }

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${images[random]})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: {
              xs: 8,
            },
            mx: {
              xs: 2,
              sm: 3,
              md: 4,
              lg: 6,
              xl: 6,
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login/Sign Up
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoFocus
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Box sx={{ textAlign: "center" }}>
              <Button
                onClick={() => handleLogin("basic", form)}
                variant="contained"
                size="large"
                sx={{ m: 2, width: "31%" }}
              >
                Login
              </Button>
              <Button
                onClick={handleSignup}
                variant="contained"
                size="large"
                sx={{ m: 2, width: "31%" }}
              >
                Sign Up
              </Button>
            </Box>
            <SocialMediaLogin handleLogin={handleLogin} handleReject={handleReject} />
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Box>
      </Grid>
    </Grid>
  )
}
