import { Button, SxProps, Theme } from "@mui/material"

import DownloadIcon from "@mui/icons-material/Download"

const BUTTON_WIDTH = 150
const BUTTON_BORDER_RADIUS = 16

type DownloadButtonProps = {
  sx?: SxProps<Theme>
  onClick?: () => void
}
/**
 * Market Place Download Button
 */
export default function DownloadButton(props: DownloadButtonProps) {
  const { sx, onClick } = props

  return (
    <Button
      sx={{
        ...sx,
        width: BUTTON_WIDTH,
        display: "inline-flex",
        alignItems: "center",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: BUTTON_BORDER_RADIUS,
        overflow: "hidden",
        textTransform: "none",
      }}
      startIcon={<DownloadIcon />}
      onClick={onClick}
    >
      Download
    </Button>
  )
}
