import React, { useEffect } from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { Button, Divider, Grid, Link, useMediaQuery } from "@mui/material"
import { Page, PageContainer, SwipeableListGroup } from "../../component"
import {
  MusicCreationBanner,
  WebThreeBanner,
  CreateAndEarnBanner,
  LaunchPartyBanner,
  JamSessionComingSoonBanner,
} from "../../assets/carousel"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import SavedMusic from "./SavedMusic"
import PurchasingRecord from "./PurchasingRecord"
import SubscriptionTab from "./SubscriptionTab"
import { useParams } from "react-router-dom"

type HistoryTab = "purchased" | "subscription" | "saved"
function isHistoryTab(tab: string): tab is HistoryTab {
  return ["purchased", "subscription", "saved"].includes(tab)
}
/**
 * History page
 */
export default function HistoryPage() {
  const isMobile = useMediaQuery("(max-width:600px)")

  const { tab } = useParams()

  const [activeTab, setActiveTab] = React.useState<HistoryTab>(
    tab && isHistoryTab(tab) ? tab : "purchased"
  )
  useEffect(() => {
    if (tab && isHistoryTab(tab)) {
      setActiveTab(tab)
    }
  }, [tab])

  return (
    <div>
      <Page pageId={HistoryPage} noPadding>
        <PageContainer>
          <Tabs
            value={activeTab}
            onChange={(event: React.SyntheticEvent, tab: HistoryTab) => setActiveTab(tab)}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab
              key={"purchased"}
              value={"purchased"}
              label={"Purchasing Record"}
              sx={{ textTransform: "none" }}
            />
            <Tab
              key={"subscription"}
              value={"subscription"}
              label={"Subscription"}
              sx={{ textTransform: "none" }}
            />
            <Tab
              key={"saved"}
              value={"saved"}
              label={"Saved music"}
              sx={{ textTransform: "none" }}
            />
          </Tabs>

          <Divider sx={{ mb: 2 }} />

          {activeTab === "purchased" && <PurchasingRecord />}
          {activeTab === "subscription" && <SubscriptionTab />}
          {activeTab === "saved" && <SavedMusic />}
        </PageContainer>
      </Page>
    </div>
  )
}
