import { Box, Card, CardContent, CardMedia, Grid, IconButton, Typography } from "@mui/material"
import { StopCircle, PlayArrow } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"

export interface MusicItemProp {
  styles?: string[]
  instruments?: string[]
  like?: string[]
  id: string
  name: string
  project?: string
  projectName: string
  musicUrl: string
  musicImageUrl: string
  playState: boolean
  handlePlayMusic: () => void
  handleStopMusic: () => void
}

export interface MusicListProp {
  allMusic: MusicItemProp[]
}

/**
 * Music Item
 */
export default function MusicItem(props: MusicItemProp) {
  const { playState, name, projectName, musicImageUrl, handleStopMusic, handlePlayMusic, id } =
    props
  const navigate = useNavigate()
  const handlePlayPauseMusic = () => {
    if (!playState) {
      handlePlayMusic()
    } else {
      handleStopMusic()
    }
  }

  return (
    <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
      <Card sx={{ display: "flex", maxHeight: 200 }} onClick={() => navigate(`/musicDetail/${id}`)}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "70%" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography component="div" variant="subtitle1">
              {projectName}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" component="div">
              {name}
            </Typography>
          </CardContent>
          <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
            <IconButton aria-label="play/pause" onClick={handlePlayPauseMusic}>
              {playState ? (
                <StopCircle sx={{ height: 38, width: 38 }} />
              ) : (
                <PlayArrow sx={{ height: 38, width: 38 }} />
              )}
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ width: "30%" }}>
          <CardMedia
            component="img"
            sx={{ width: "100%", height: "100%" }}
            image={musicImageUrl}
            alt={name}
          />
        </Box>
      </Card>
    </Grid>
  )
}
