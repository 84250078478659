import {
  ArrangementPNG,
  BassesPNG,
  BrassPNG,
  ComposerPNG,
  DjPNG,
  DrumsPNG,
  FolkAndTraditionalPNG,
  GuitarPNG,
  KeyboardMidiPNG,
  OthersPNG,
  PianoPNG,
  ProducerPNG,
  SongWriterPNG,
  SoundEngineerPNG,
  StringsPNG,
  VocalPNG,
  WoodwindsPNG,
} from "../../assets/instruments"

export interface InstrumentCategoriesProps {
  label: string
  value?: string
  options?: {
    label: string
    value: string
  }[]
}

// Mapping Icon with Instrument label
// Refer to: womucon-apps/packages/share/constant/instruments.js
export const renderIcon = (instrumentLabel: string) => {
  switch (instrumentLabel) {
    case "Guitar":
      return GuitarPNG
    case "Folk & Traditional Stringed Instrument":
      return FolkAndTraditionalPNG
    case "Basses":
      return BassesPNG
    case "Drums/Percussion":
      return DrumsPNG
    case "Keyboard & MIDI":
      return KeyboardMidiPNG
    case "DJ":
      return DjPNG
    case "Piano":
      return PianoPNG
    case "Vocal (Male)":
      return VocalPNG
    case "Vocal (Female)":
      return VocalPNG
    case "WoodWinds":
      return WoodwindsPNG
    case "Brass":
      return BrassPNG
    case "String":
      return StringsPNG
    case "Mix Engineer":
      return SoundEngineerPNG
    case "Music Producer":
      return ProducerPNG
    case "Songwriter":
      return SongWriterPNG
    case "Music Arranger":
      return ProducerPNG
    case "Composer":
      return ComposerPNG
    case "Arrangement":
      return ArrangementPNG
    case "Other":
      return OthersPNG
    default:
      console.log("No icon")
  }
}
