import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline"
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Input,
  Paper,
  Stack,
  Typography,
  Grid,
} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import { useNavigate } from "react-router-dom"
import { colors } from "../../theme/colors"
import { useMediaQuery } from "@mui/material"
import { ChangeEvent, useRef, useState } from "react"

import { UserAPI } from "../../api"
import { Modal } from ".." // Ensure you have these components available
import CoverImageCrop from "../CoverImageCrop/CoverImageCrop"

type ProfileHeaderProps = {
  name: string
  followers: number
  styleOfInterest: string[]
  instruments: { type: string; year: number }[]
  profileImageUrl: string
  coverImageUrl: string
  isSelf: boolean
  description: string
}

/**
 * Profile Header
 */
export default function ProfileHeader({
  name,
  followers,
  styleOfInterest,
  instruments,
  profileImageUrl,
  coverImageUrl,
  isSelf,
  description,
}: ProfileHeaderProps) {
  const navigate = useNavigate()
  const isMobile = useMediaQuery("(max-width:600px)")

  const formatFollowers = (followers: number): string => {
    if (followers < 1000) {
      return followers.toString()
    }
    if (followers < 1000000) {
      return (followers / 1000).toFixed(1) + "k"
    }
    return (followers / 1000000).toFixed(1) + "m"
  }

  const coverImageRef = useRef<HTMLInputElement>(null)
  const [open, setOpen] = useState(false)
  const [imgSrc, setImgSrc] = useState(coverImageUrl)

  const handleClearImage = () => {
    setImgSrc("")
    if (!coverImageRef.current) return

    coverImageRef.current.value = ""
  }

  const handleAddImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setOpen(true)
      const reader = new FileReader()
      reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const handleSaveImage = async (url: string, file: File) => {
    setImgSrc(url)
    setOpen(false)
    await UserAPI.uploadCoverImage(file)
  }

  return (
    <Paper sx={{ backgroundColor: "black", paddingBottom: "15px" }}>
      <Stack justifyContent="center" alignItems="center">
        <Paper
          sx={{
            backgroundImage: `url(${imgSrc})`,
            height: 300,
            width: "100%",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          {isSelf && (
            <Button
              sx={{
                color: colors.orange,
                float: "right",
              }}
              component="label"
              startIcon={<EditIcon color="primary" />}
            >
              <Input
                type="file"
                sx={{ maxHeight: 0, opacity: 0, maxWidth: 0 }}
                inputProps={{ accept: "image/*" }}
                onChange={handleAddImage}
                onClick={handleClearImage}
              />
            </Button>
          )}
        </Paper>
      </Stack>

      <Modal open={open} handleToggleClose={() => setOpen(false)} fullScreen={isMobile}>
        <CoverImageCrop imgSrc={imgSrc} handleSaveImage={handleSaveImage} />
      </Modal>

      <Grid container sx={{ ml: 3 }}>
        <Grid item xs={12} sm={1.5}>
          <Avatar
            sx={{
              width: isMobile ? 100 : 150,
              height: isMobile ? 100 : 150,
              mt: -5,
              mr: 5,
            }}
            alt={name}
            src={profileImageUrl}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Stack sx={{ mt: 3, ml: isMobile ? 0 : 2 }} alignItems={"flex-start"}>
            <Stack direction={"row"} alignItems={isMobile ? "center" : "flex-start"}>
              <Typography variant="h5" gutterBottom>
                {name || "WMC User"}
              </Typography>
              {isSelf && (
                <IconButton
                  onClick={() => navigate(`/user/self/edit`)}
                  edge="start"
                  sx={{ color: colors.orange, ml: isMobile ? 0 : 1, mt: isMobile ? 1 : 0 }}
                >
                  <EditIcon />
                  <Typography>Edit Profile</Typography>
                </IconButton>
              )}
            </Stack>

            <Box sx={{ textAlign: "center", mt: isMobile ? 2 : 0 }}>
              <Stack direction="row" alignItems="center">
                <PeopleOutlineIcon />
                <Typography variant="subtitle1">{formatFollowers(followers)} Followers</Typography>
              </Stack>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" sx={{ color: colors.grey2 }}>
                  Style
                </Typography>
                <Stack direction="row" flexWrap="wrap" justifyContent={"flex-start"}>
                  {styleOfInterest?.map((style: string) => (
                    <Typography
                      key={style}
                      variant="subtitle1"
                      px={"10px"}
                      border={"1px solid"}
                      borderRadius={"20px"}
                      borderColor={"white"}
                      mr={"5px"}
                      mt={"5px"}
                    >
                      {style}
                    </Typography>
                  ))}
                </Stack>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" sx={{ color: colors.grey2 }}>
                  Instruments
                </Typography>
                <Stack direction="row" flexWrap="wrap" justifyContent={"flex-start"}>
                  {instruments?.map((instrument: { type: string; year: number }) => (
                    <Typography
                      key={instrument.type}
                      variant="subtitle1"
                      px={"10px"}
                      border={"1px solid"}
                      borderRadius={"20px"}
                      borderColor={"white"}
                      mr={"5px"}
                      mt={"5px"}
                    >
                      {instrument.type}
                    </Typography>
                  ))}
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" sx={{ color: colors.grey2 }}>
                  Bio
                </Typography>
                <Typography variant="subtitle1" textAlign={"left"}>
                  {description}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  )
}
